import AgentBiographicInfoCard, {
  AgentBiographicInfo,
  groupStateAssignments,
} from '../../../components/agentBiographicInfo/agentBiographicInfo';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Typography,
  message,
  notification,
} from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ReactNode, useEffect, useState } from 'react';
import USMapView, { USMapViewDataProp } from '../../../components/usMap';
import {
  formatPhoneAndFax,
  isPhoneNumberValid,
} from '../../../utils/form-utils/format-phone-fax.utils';
import {
  generateAssignmentErrorMessage,
  isErrorStripeOnboardingRequired,
} from '../../../utils/errorUtils';
import { groupBy, isEqual, omit } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import { AddressDetails } from '../../../types/data/addressDetails.type';
import AddressInput from '../../../components/common/addressInput';
import { AddressType } from '../../../enums/addressType.enum';
import { AgencyService } from '../../../services/agency.service';
import { AgentService } from '../../../services/agent.service';
import AssignModal from '../../../components/assignModal/assignModal';
import { AssignedState } from '../../../types/data/stateGroup.types';
import { GenderType } from '../../../enums/genderType.enum';
import { IdConstants } from '../../../constants/id.constants';
import { PaymentConfig } from '../../../types/response/data/agents/paymentConfig.type';
import PaymentConfigurations from './agentOverview/paymentConfig';
import { ProducerDetails } from '../onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import StripeOnboardingRequiredToast from '../../../components/common/stripeOnboardingRequiredToast';
import TextWithIcon from '../../../components/common/textWithIcon/textWithIcon';
import { adminStore } from '../../../stores/admin.store';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import dayjs from 'dayjs';
import { getCustomizedAgentAssignmentDataForMaps } from '../../../utils/map.utils';
import { getNameString } from '../../../utils/name.utils';
import { isXDaysOld } from '../../../utils/date.utils';
import { maskSSN } from '../../../utils/hash.utils';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../../auth/authProvider';
import { withRoles } from '../../../auth/useRoles';

export interface HomeStateAddress {
  addresses: AddressDetails[];
  state: string;
}

export interface ProcessingFlags {
  isLicenseProcessing: boolean;
  isAssignmentProcessing: boolean;
}

export interface AgentInfo {
  id: string;
  name: {
    firstName: string;
    lastName: string;
    middleName?: string;
  };
  gender: string;
  dateOfBirth: dayjs.Dayjs;
  businessEmail: string;
  agencyId: string;
  fax: string;
  phone: string;
  npn: string;
  ssn: string;
  stateAddresses: HomeStateAddress;
  assignments: AssignedState[];
  paymentConfig: PaymentConfig;
  autoRenewEnabled: boolean;
  selfAmountUsed: number;
  parentAmountUsed: number;
  homeState: string;
  processingFlags: ProcessingFlags;
}

function AgencyPortalAgentOverview() {
  const { agentId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { bearerToken, getAccessTokenSilently } = useAuth();
  const [isBioInfoEditing, setIsBioInfoEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mapLoader, setMapLoader] = useState(true);
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [bioInfoLoading, setBioInfoLoading] = useState(false);
  const [stateDataForMap, setStateDataForMap] = useState<USMapViewDataProp>({});
  const [isAddressUpdatedInXDays, setIsAddressUpdatedInXDays] = useState(false);
  const [licenseColorData, setLicenseColor] = useState<{
    [key: string]: string;
  }>({});
  const [isAssignmentsFetching, setIsAssignmentsFetching] = useState('');
  const [assignedStates, setAssignedStates] = useState<AssignedState[] | null>(
    null
  );
  const [ssn, setSsn] = useState<string>('');

  const [checkAppliedMailing, setCheckAppliedMailing] = useState(false);
  const [checkAppliedResidence, setCheckAppliedResidence] = useState(false);

  const handleMailingCheckboxChange = () => {
    setCheckAppliedMailing((prev) => !prev);

    if (!checkAppliedMailing) {
      const businessAddress = form.getFieldsValue([
        'baddressLine1',
        'baddressLine2',
        'baddressLine3',
        'bcity',
        'bstate',
        'bzipcode',
        'bcountry',
      ]);
      form.setFieldsValue({
        maddressLine1: businessAddress.baddressLine1,
        maddressLine2: businessAddress.baddressLine2,
        maddressLine3: businessAddress.baddressLine3,
        mcity: businessAddress.bcity,
        mstate: businessAddress.bstateCode,
        mzipcode: businessAddress.bzipcode,
        mcountry: businessAddress.bcountry,
      });
    } else {
      const prevMailingAddress = getAddressForInputData('3', 'Mailing');
      form.setFieldsValue({
        maddressLine1: prevMailingAddress.addressLine1,
        maddressLine2: prevMailingAddress.addressLine2,
        maddressLine3: prevMailingAddress.addressLine3,
        mcity: prevMailingAddress.city,
        mstate: prevMailingAddress.stateCode,
        mzipcode: prevMailingAddress.zip,
        mcountry: prevMailingAddress.country,
      });
    }
  };

  const handleIndividualAssignStates = async () => {
    if (!agentId) return;
    setIsAssignmentsFetching(agentId);
    try {
      const token = await getAccessTokenSilently();
      const response: any =
        await AgentService.getLicenseConfigurationByProducerId(agentId, token);
      setAssignedStates(
        response?.data?.map((d: any) => {
          const liceseConfig = groupBy(
            d?.licenseConfigurations,
            'licenseClassCode'
          );
          return {
            stateGroupId: d?.territoryId || '',
            stateGroupName: d?.territoryName || null,
            stateCode: d?.stateCode,
            licenseConfigs: Object.values(liceseConfig)?.map((d) => {
              return {
                lcCode: d?.[0]?.licenseClassCode,
                loaCodes: d?.map((d) => d?.loaCode),
                loaids: d?.map((d) => d?._id || d?.id),
              };
            }),
          };
        }) || null
      );
      setIsAssignModalVisible(true);
    } catch (error) {
      console.error(error);
      message.error('Failed to get producer assignments.Please Try Again');
      setIsAssignModalVisible(false);
    } finally {
      setIsAssignmentsFetching('');
    }
  };

  const handleResidenceCheckboxChange = () => {
    setCheckAppliedResidence((prev) => !prev);

    if (!checkAppliedResidence) {
      const businessAddress = form.getFieldsValue([
        'baddressLine1',
        'baddressLine2',
        'baddressLine3',
        'bcity',
        'bstate',
        'bzipcode',
        'bcountry',
      ]);
      form.setFieldsValue({
        raddressLine1: businessAddress.baddressLine1,
        raddressLine2: businessAddress.baddressLine2,
        raddressLine3: businessAddress.baddressLine3,
        rcity: businessAddress.bcity,
        rstate: businessAddress.bstateCode,
        rzipcode: businessAddress.bzipcode,
        rcountry: businessAddress.bcountry,
      });
    } else {
      const prevResidentAddress = getAddressForInputData('1', 'Residence');
      form.setFieldsValue({
        raddressLine1: prevResidentAddress.addressLine1,
        raddressLine2: prevResidentAddress.addressLine2,
        raddressLine3: prevResidentAddress.addressLine3,
        rcity: prevResidentAddress.city,
        rstate: prevResidentAddress.stateCode,
        rzipcode: prevResidentAddress.zip,
        rcountry: prevResidentAddress.country,
      });
    }
  };
  const { Option } = Select;

  const [agent, setAgent] = useState<ProducerDetails>();

  const [api, contextHolder] = notification.useNotification();
  const showError = (errorMessage: ReactNode) => {
    api['error']({
      message: 'Error',
      description: errorMessage,
    });
  };

  const assignStateModalOnCloseCallback = (isCancel?: boolean) => {
    if (!isCancel) {
      loadAgent();
      getMapData();
    }
  };

  const loadAgent = async () => {
    setIsLoading(true);
    try {
      if (agentId && bearerToken) {
        const response = await AgentService.getProducerDetailsById(
          agentId,
          bearerToken
        ).catch((error) => {
          console.error('Error:: ', error.message);
        });
        if (response?.data) {
          appStateInfoStore.setCurrentAgentId(response.data?.id);
          appStateInfoStore.setHeader(getNameString(response.data?.name || ''));
          setAgent(response.data);
          setSsn(response.data?.ssn);
        }
      }
    } catch (error) {
      console.error('loadAgent :: Error ::', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMapData = async () => {
    try {
      setMapLoader(true);
      if (agentId && bearerToken) {
        const licenseDetails: any = await AgentService.getLicenseStatusByAgent(
          agentId,
          bearerToken
        ).catch((error) => {
          console.error('Error:: ', error.message);
        });
        const licenseColor: { [key: string]: string } = {};
        licenseDetails?.forEach(
          (data: { stateCode: string; color: string }) => {
            licenseColor[data.stateCode] = data.color;
          }
        );
        setLicenseColor(licenseColor);
      }
    } catch (error) {
      console.error('getMapsData :: Error ::', error);
    } finally {
      setMapLoader(false);
    }
  };

  useEffect(() => {
    loadAgent();
    getMapData();
  }, []);

  useEffect(() => {
    if (!isLoading && !mapLoader) {
      const updatedStateAssignments = groupStateAssignments(
        agent?.stateAssignments?.[0]?.stateAssignments
      );
      const response = getCustomizedAgentAssignmentDataForMaps(
        updatedStateAssignments,
        licenseColorData
      );

      setStateDataForMap(response);
    }
  }, [isLoading, mapLoader]);

  useEffect(() => {
    if (
      agent?.addressLastUpdatedAt &&
      !isXDaysOld(agent?.addressLastUpdatedAt, 3)
    ) {
      setIsAddressUpdatedInXDays(true);
    }
  }, [agent]);

  const getAddressForInputData = (
    code: string,
    type: string
  ): AddressDetails => {
    const dataVariable = agent?.address?.find(
      (data) => data.addressType === type
    );
    return {
      addressTypeCode: code,
      addressType: type,
      addressLine1: dataVariable?.addressLine1 || '',
      addressLine2: dataVariable?.addressLine2 || '',
      addressLine3: dataVariable?.addressLine3 || '',
      city: dataVariable?.city || '',
      state: dataVariable?.state || '',
      stateCode: dataVariable?.stateCode || '',
      zip: dataVariable?.zip || '',
      country: 'U.S.A',
    };
  };

  const updateAgentBioInfo = (updatedObject: any) => {
    setAgent((prev: any) => {
      if (prev) {
        const newAgent = { ...prev };
        newAgent.businessEmail = updatedObject.businessEmail;
        newAgent.fax = updatedObject.fax;
        newAgent.businessPhone = updatedObject.phone;
        newAgent.gender = updatedObject.gender;
        newAgent.dateOfBirth = updatedObject.dateOfBirth;
        newAgent.stateAddresses = updatedObject.stateAddresses;
        newAgent.address = updatedObject.stateAddresses[0].addresses;
        newAgent.ssn = updatedObject.ssn || prev?.ssn || '';
        setSsn(maskSSN(updatedObject?.ssn || prev?.ssn) || '');
        return newAgent;
      }
      return prev;
    });
  };

  //commenting this out as it might be used later for handling some usecase
  // function convertMomentToDate(momentObj: moment.Moment): Date {
  //   const defaultDate = new Date(0);
  //   defaultDate.setFullYear(momentObj.year());
  //   defaultDate.setMonth(momentObj.month());
  //   defaultDate.setDate(momentObj.date());
  //   return defaultDate;
  // }

  function convertMomentToString(momentObj: moment.Moment): string {
    return momentObj.format('YYYY-MM-DD');
  }

  const compareAddresses = (
    updatedAddress: Record<string, any>,
    agentPrevAddress: Record<string, any>
  ) => {
    const updatedAddressAfterOmit: Record<string, any> = {};
    for (const key in updatedAddress) {
      if (Object.prototype.hasOwnProperty.call(updatedAddress, key)) {
        updatedAddressAfterOmit[key] = omit(updatedAddress[key], 'dateUpdated');
      }
    }
    const agentPrevAddressAfterOmit: Record<string, any> = {};
    for (const key in agentPrevAddress) {
      if (Object.prototype.hasOwnProperty.call(agentPrevAddress, key)) {
        agentPrevAddressAfterOmit[key] = omit(agentPrevAddress[key], [
          'dateUpdated',
          '_id',
        ]);
      }
    }
    return isEqual(updatedAddressAfterOmit, agentPrevAddressAfterOmit);
  };

  const handleInfoEdit = async (values: any) => {
    setBioInfoLoading(true);
    let updatedAgent = agent;

    let addresses = [
      {
        dateUpdated: new Date().toISOString(),
        addressTypeCode: '1',
        addressType: AddressType.RESIDENCE,
        addressLine1: values.raddressLine1
          ? values.raddressLine1.trim()
          : values.raddressLine1,
        addressLine2: values.raddressLine2
          ? values.raddressLine2.trim()
          : values.raddressLine2,
        addressLine3: values.raddressLine3
          ? values.raddressLine3.trim()
          : values.raddressLine3,
        city: values.rcity,
        stateCode: values.rstate,
        zip: values.rzipcode,
        country: values.rcountry,
      },
      {
        dateUpdated: new Date().toISOString(),
        addressTypeCode: '2',
        addressType: AddressType.BUSINESS,
        addressLine1: values.baddressLine1
          ? values.baddressLine1.trim()
          : values.baddressLine1,
        addressLine2: values.baddressLine2
          ? values.baddressLine2.trim()
          : values.baddressLine2,
        addressLine3: values.baddressLine3
          ? values.baddressLine3.trim()
          : values.baddressLine3,
        city: values.bcity,
        stateCode: values.bstate,
        zip: values.bzipcode,
        country: values.bcountry,
      },
      {
        dateUpdated: new Date().toISOString(),
        addressTypeCode: '3',
        addressType: AddressType.MAILING,
        addressLine1: values.maddressLine1
          ? values.maddressLine1.trim()
          : values.maddressLine1,
        addressLine2: values.maddressLine2
          ? values.maddressLine2.trim()
          : values.maddressLine2,
        addressLine3: values.maddressLine3
          ? values.maddressLine3.trim()
          : values.maddressLine3,
        city: values.mcity,
        stateCode: values.mstate,
        zip: values.mzipcode,
        country: values.mcountry,
      },
    ];
    let updateObject: any;
    const agentAddresses = agent?.address ?? [];
    const isAddressUpdated = !compareAddresses(addresses, agentAddresses);
    if (isAddressUpdated) {
      updateObject = {
        businessEmail: values['email'],
        fax: values['fax'],
        phone: values['phone'],
        dateOfBirth: convertMomentToString(values['dateOfBirth']),
        gender: values['gender'],
        stateAddresses: {
          stateCode: agent?.residentState,
          addresses: addresses,
        },
      };
    } else {
      updateObject = {
        businessEmail: values['email'],
        fax: values['fax'],
        phone: values['phone'],
        dateOfBirth: convertMomentToString(values['dateOfBirth']),
        gender: values['gender'],
      };
    }
    if (values['ssn'] && values['ssn'][0] !== '*')
      updateObject.ssn = values['ssn'];

    setAgent(updatedAgent);
    setSsn(updatedAgent?.ssn || '');

    setIsBioInfoEditing(!isBioInfoEditing);

    if (agentId && bearerToken) {
      try {
        const updatedAgentAfterSave: any =
          await AgencyService.updateProducerDetails(
            agentId,
            updateObject,
            bearerToken
          );
        if (
          updatedAgentAfterSave?.addressLastUpdatedAt &&
          !isXDaysOld(updatedAgentAfterSave?.addressLastUpdatedAt, 3)
        ) {
          setIsAddressUpdatedInXDays(true);
        }
        updateAgentBioInfo({
          ...updateObject,
          stateAddresses: [
            { state: agent?.residentState, addresses: addresses },
          ],
        });
        if (isAddressUpdated) setIsAddressUpdatedInXDays(true);
      } catch (error: any) {
        updateAgentBioInfo({
          ...updateObject,
          stateAddresses: [
            { state: agent?.residentState, addresses: agentAddresses },
          ],
        });
        if (isErrorStripeOnboardingRequired(error.response.data))
          showError(<StripeOnboardingRequiredToast />);
        else
          notification.error({
            message: 'Error',
            description: `${error?.response?.data?.error?.message}.`,
          });
      }
    }
    setBioInfoLoading(false);
  };

  const getTooltip = () => {
    if (isAssignmentsFetching) return null;

    let tooltipTitle;

    if (
      agent?.processingFlags?.isLicenseProcessing &&
      agent?.processingFlags?.isAssignmentProcessing
    ) {
      tooltipTitle =
        'License(s) and Assignment(s) are currently being processed';
    } else if (agent?.processingFlags?.isLicenseProcessing) {
      tooltipTitle = 'License(s) currently being processed';
    } else if (agent?.processingFlags?.isAssignmentProcessing) {
      tooltipTitle = 'Assignment(s) currently being processed';
    } else {
      tooltipTitle = null;
    }

    return tooltipTitle;
  };

  const isDisabled =
    !agent?.residentState ||
    !!isAssignmentsFetching ||
    agent?.processingFlags?.isAssignmentProcessing ||
    agent?.processingFlags?.isLicenseProcessing;

  return (
    <div style={{ width: '100%' }}>
      {contextHolder}
      <Card className="biographic-info">
        <Row style={{ justifyContent: 'space-between' }}>
          <Typography.Paragraph
            style={{ fontSize: '16px', fontWeight: 500, marginBottom: 0 }}
          >
            Biographic Info
          </Typography.Paragraph>
          {isBioInfoEditing ? (
            <Row>
              <Button
                id={IdConstants.AGENT_INFO.CANCEL_BUTTON}
                onClick={() => {
                  setCheckAppliedMailing(false);
                  setCheckAppliedResidence(false);
                  setIsBioInfoEditing(!isBioInfoEditing);
                  setSsn('');
                }}
                style={{
                  marginRight: '10px',
                  borderColor: 'var(--secondary-color)',
                  color: 'var(--secondary-color)',
                }}
              >
                Cancel
              </Button>
              <Form.Item>
                <Button
                  id={IdConstants.AGENT_INFO.SAVE_BUTTON}
                  htmlType="submit"
                  className="button secondary-button"
                  onClick={() => {
                    setCheckAppliedMailing(false);
                    setCheckAppliedResidence(false);
                    form.submit();
                  }}
                >
                  Update
                </Button>
              </Form.Item>
            </Row>
          ) : (
            // agent?.agencyId?.id === adminStore.agency?.id
            // &&
            !(isLoading || mapLoader) && (
              <Space>
                <Tooltip
                  title={
                    !agent?.residentState
                      ? 'Producer does not have a home state. Individual assignment is disabled.'
                      : getTooltip()
                  }
                >
                  {' '}
                  <Button
                    id={IdConstants.AGENT_INFO.MANAGE_ASSIGNMENT}
                    size="middle"
                    type="primary"
                    className="button"
                    disabled={isDisabled}
                    onClick={() => {
                      navigate(
                        RouteConstants.manageAssignmentsByProducers.path.replace(
                          ':producerId',
                          agentId!
                        )
                      );
                    }}
                    loading={!!isAssignmentsFetching}
                  >
                    Manage Assignments
                  </Button>
                </Tooltip>
                <Button
                  id={IdConstants.AGENT_INFO.EDIT_BIO_INFO}
                  onClick={() => {
                    setIsBioInfoEditing(!isBioInfoEditing);
                    form.resetFields();
                  }}
                  disabled={bioInfoLoading}
                >
                  <EditOutlined /> Edit
                </Button>
              </Space>
            )
          )}
        </Row>
        <hr className="primary-line" style={{ marginTop: '20px' }} />
        <div>
          {isBioInfoEditing ? (
            <div style={{ marginTop: '20px' }}>
              <Form
                layout="vertical"
                scrollToFirstError={{
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center',
                }}
                form={form}
                onFieldsChange={(changedValues, allValues) => {
                  // if the checkbox is checked for mailing perform this
                  if (checkAppliedMailing) {
                    const updatedMailingFields: { [key: string]: any } = {};
                    allValues.forEach((field) => {
                      const fieldName = field.name as string; // Type assertion
                      // if bstate is for business then mstate is for mailing
                      if (fieldName[0].startsWith('b')) {
                        const mailingKey = fieldName[0].replace(
                          'b',
                          'm'
                        ) as keyof typeof updatedMailingFields; // Type assertion
                        const mailingField = allValues.find(
                          (v) => v.name === mailingKey
                        );
                        //if its values are not matching then replace business values to mail
                        if (
                          field.value !== mailingField?.value ||
                          mailingField?.value === undefined
                        ) {
                          updatedMailingFields[mailingKey] = field.value;
                        }
                      }
                    });
                    form.setFieldsValue(updatedMailingFields);
                  }

                  if (checkAppliedResidence) {
                    const updatedResidenceFields: { [key: string]: any } = {};
                    allValues.forEach((field) => {
                      const fieldName = field.name as string;
                      if (fieldName[0].startsWith('b')) {
                        // if bstate is for business then rstate is for residence
                        const residenceKey = fieldName[0].replace(
                          'b',
                          'r'
                        ) as keyof typeof updatedResidenceFields;
                        const residenceField = allValues.find(
                          (v) => v.name === residenceKey
                        );
                        if (
                          field.value !== residenceField?.value ||
                          residenceField?.value === undefined
                        ) {
                          updatedResidenceFields[residenceKey] = field.value;
                        }
                      }
                    });
                    form.setFieldsValue(updatedResidenceFields);
                  }
                }}
                onFinish={handleInfoEdit}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[{ required: true }]}
                    style={{ width: '33%' }}
                    initialValue={agent?.name?.firstName}
                  >
                    <Input
                      className="display-input"
                      disabled
                      placeholder="Enter a First Name"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[{ required: true }]}
                    style={{ width: '33%' }}
                    initialValue={agent?.name?.lastName}
                  >
                    <Input
                      className="display-input"
                      disabled
                      placeholder="Enter a Last Name"
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={agent?.businessEmail}
                    label="Email Address"
                    name="email"
                    rules={[
                      { required: true },
                      {
                        type: 'email',
                        message: 'Please enter a valid email address',
                      },
                    ]}
                    style={{ width: '33%' }}
                  >
                    <Input
                      onInput={(e: any) =>
                        (e.target.value = e.target.value.toLowerCase())
                      }
                      placeholder="Enter an Email Address"
                    />
                  </Form.Item>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Form.Item
                    label="Date of Birth"
                    name="dateOfBirth"
                    initialValue={dayjs(agent?.dateOfBirth)}
                    rules={[
                      {
                        required: true,
                        message: 'Please select your date of birth',
                      },
                    ]}
                    style={{ width: '33%' }}
                  >
                    <DatePicker
                      key="dateOfBirth"
                      format="MM/DD/YYYY"
                      disabledDate={(current) =>
                        !current.isBefore(
                          dayjs().subtract(18, 'years').toDate()
                        ) &&
                        current.isAfter(dayjs().subtract(100, 'years').toDate())
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Gender"
                    name="gender"
                    initialValue={agent?.gender || undefined}
                    rules={[
                      {
                        required: true,
                        message: 'Please select your gender',
                      },
                    ]}
                    style={{ width: '33%' }}
                  >
                    <Select placeholder="Select Gender">
                      <Option value={GenderType.Male}>Male</Option>
                      <Option value={GenderType.Female}>Female</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Fax"
                    name="fax"
                    style={{ width: '33%' }}
                    initialValue={agent?.fax}
                  >
                    <Input
                      placeholder="Enter a FAX Number"
                      onChange={(e) => {
                        const formattedValue = formatPhoneAndFax(
                          e.target.value
                        );
                        if (
                          !e.target.value ||
                          isPhoneNumberValid(formattedValue)
                        ) {
                          form.setFieldsValue({ fax: formattedValue });
                        }
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        const pastedText =
                          event.clipboardData.getData('text/plain');
                        if (!/^[0-9]+$/.test(pastedText)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </div>

                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Form.Item
                    label="Phone"
                    name="phone"
                    style={{ width: '33%' }}
                    initialValue={agent?.businessPhone}
                    rules={[
                      { required: true, message: 'Please enter Phone Number' },
                    ]}
                  >
                    <Input
                      placeholder="Enter a Phone Number"
                      onChange={(e) => {
                        const formattedValue = formatPhoneAndFax(
                          e.target.value
                        );
                        if (
                          !e.target.value ||
                          isPhoneNumberValid(formattedValue)
                        ) {
                          form.setFieldsValue({ phone: formattedValue });
                        }
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        const pastedText =
                          event.clipboardData.getData('text/plain');
                        if (!/^[0-9]+$/.test(pastedText)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '33%',
                    }}
                  >
                    <Form.Item
                      label="SSN"
                      name="ssn"
                      style={{ width: '90%', marginRight: '10px' }}
                      initialValue={ssn || agent?.ssn || ''}
                      rules={[
                        {
                          required: true,
                        },
                        {
                          pattern: /^.{6,9}$/,
                          message: 'SSN must be between 6 and 9 digits',
                        },
                      ]}
                    >
                      <Input
                        className="display-input"
                        placeholder="Enter a Social Security Number"
                        value={ssn}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.length <= 9) setSsn(value);
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(event) => {
                          const pastedText =
                            event.clipboardData.getData('text/plain');
                          if (!/^[0-9]+$/.test(pastedText)) {
                            event.preventDefault();
                          }
                        }}
                        onKeyDown={(event) => {
                          if (
                            event.key === 'Backspace' &&
                            (ssn.length === 9 ||
                              (agent?.ssn?.length === 9 && ssn.length === 0))
                          ) {
                            form.setFieldsValue({ ssn: '' });
                            setSsn('');
                            event.preventDefault();
                          }
                        }}
                        minLength={9}
                        maxLength={9}
                      />
                    </Form.Item>
                    <Form.Item
                      label="NPN"
                      name="npn"
                      rules={[{ required: true }]}
                      style={{ width: '90%' }}
                      initialValue={agent?.npn}
                    >
                      <Input
                        className="display-input"
                        disabled
                        placeholder="Enter a National Producer Number"
                      />
                    </Form.Item>
                  </div>

                  <div style={{ width: '33%' }}></div>
                </div>

                {agent && (
                  <>
                    {/* {isAddressUpdatedInXDays && (
                      <div style={{ marginTop: '20px', marginBottom: '15px' }}>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: '14px', marginBottom: 0 }}
                        >
                          <Space>
                            <InfoCircleOutlined />
                            Address update has been submitted for review to the
                            State department. Please await further updates.
                          </Space>
                        </Typography.Text>
                      </div>
                    )} */}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between ',
                      }}
                    >
                      {isBioInfoEditing && (
                        <AddressInput
                          // viewOnly={isAddressUpdatedInXDays}
                          addressDetails={getAddressForInputData(
                            '2',
                            'Business'
                          )}
                          addressType="business"
                          style={{ width: '33%', display: 'block' }}
                          form={form}
                        />
                      )}
                      {isBioInfoEditing && (
                        <AddressInput
                          viewOnly={
                            // isAddressUpdatedInXDays ||
                            checkAppliedMailing
                          }
                          addressDetails={getAddressForInputData(
                            '3',
                            'Mailing'
                          )}
                          addressType="mailing"
                          style={{ width: '33%', display: 'block' }}
                          form={form}
                          checkboxState={checkAppliedMailing}
                          handleCheckBoxChange={handleMailingCheckboxChange}
                          disableCheckbox={isAddressUpdatedInXDays}
                        />
                      )}
                      {isBioInfoEditing && (
                        <AddressInput
                          viewOnly={
                            // isAddressUpdatedInXDays ||
                            checkAppliedResidence
                          }
                          addressDetails={getAddressForInputData(
                            '1',
                            'Residence'
                          )}
                          addressType="residence"
                          style={{ width: '33%', display: 'block' }}
                          form={form}
                          checkboxState={checkAppliedResidence}
                          handleCheckBoxChange={handleResidenceCheckboxChange}
                          disableCheckbox={isAddressUpdatedInXDays}
                        />
                      )}
                    </div>
                  </>
                )}
              </Form>
            </div>
          ) : (
            <AgentBiographicInfoCard
              isLoading={isLoading || bioInfoLoading || mapLoader}
              agent={agent}
              isDisabled={isDisabled}
              onAssignmentClick={() => {
                if (isDisabled) return;
                navigate(
                  RouteConstants.manageAssignmentsByProducers.path.replace(
                    ':producerId',
                    agentId!
                  )
                );
              }}
            />
          )}
        </div>
      </Card>

      <Card style={{ marginTop: 15, marginBottom: 15 }}>
        <Row style={{ justifyContent: 'space-between' }}>
          <Col>
            <Typography.Paragraph
              style={{ fontSize: '16px', fontWeight: 500, marginBottom: 0 }}
            >
              Assignments
            </Typography.Paragraph>
            {(agent?.processingFlags?.isAssignmentProcessing ||
              agent?.processingFlags?.isLicenseProcessing) && (
              <Typography.Paragraph>
                <TextWithIcon
                  iconColor="#97BFBF"
                  textStyle={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: '#97BFBF',
                  }}
                  text="Updating assignments. This may require a few seconds. Please wait!"
                  iconAlignment="left"
                  icon={<InfoCircleOutlined />}
                />
              </Typography.Paragraph>
            )}
          </Col>
        </Row>
        <hr className="primary-line" style={{ marginTop: '10px' }} />
        {isLoading || mapLoader ? (
          <Skeleton active />
        ) : (
          <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            <USMapView data={stateDataForMap} />
          </Row>
        )}
      </Card>

      <Card style={{ marginTop: 15, marginBottom: 15 }}>
        <Typography.Paragraph style={{ fontSize: '16px', fontWeight: 500 }}>
          Payment Configurations
        </Typography.Paragraph>
        <hr className="primary-line" style={{ marginTop: '20px' }} />
        {isLoading || mapLoader ? (
          <Skeleton active />
        ) : (
          <PaymentConfigurations agent={agent} />
        )}
      </Card>

      {isAssignModalVisible && (
        <AssignModal
          agentIds={[agentId as string]}
          currentAssignedStates={assignedStates ?? []}
          isModalVisible={isAssignModalVisible}
          onClose={assignStateModalOnCloseCallback}
          handleVisibility={setIsAssignModalVisible}
          homeState={agent ? [agent?.residentState] : []}
          responseCallback={(response) => {
            if (
              response?.data?.data?.errorResponses &&
              response?.data?.data?.errorResponses.length > 0
            ) {
              showError(generateAssignmentErrorMessage(response?.data?.data));
            }
          }}
        />
      )}
    </div>
  );
}

export default withRoles(observer(AgencyPortalAgentOverview), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
