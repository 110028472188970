import { ConfigProvider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RouteConstants } from '../../constants/routes.constants';
import Tooltip from 'antd/lib/tooltip';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { getNameString } from '../../utils/name.utils';
import { useNavigate } from 'react-router';
interface associateProps {
  id: string;
  agentName: any;
  agentId: string;
  isLoading?: boolean;
  isLicenseProcessing?: boolean;
  isAssignmentProcessing?: boolean;
}

function AssociatedAgent({
  id,
  agentName,
  agentId,
  isLoading,
  isLicenseProcessing,
  isAssignmentProcessing,
}: associateProps) {
  const navigate = useNavigate();
  const redirect = () => {
    appStateInfoStore.setHeader(getNameString(agentName));
    navigate(RouteConstants.agentOverview.path.replace(':agentId', agentId));
  };
  let tooltipTitle = '';

  if (isLicenseProcessing && isAssignmentProcessing) {
    tooltipTitle = 'License(s) and Assignment(s) are currently being processed';
  } else if (isLicenseProcessing) {
    tooltipTitle = 'License(s) currently being processed';
  } else if (isAssignmentProcessing) {
    tooltipTitle = 'Assignment(s) currently being processed';
  }
  return (
    <div
      id={id}
      style={{
        color: 'var(--primary-color)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: '1em',
        fontSize: 12,
        lineHeight: '16px',
        fontFamily: 'figtree',
      }}
    >
      {agentName ? (
        <>
          <div
            style={{
              color: '#f00',
              fontWeight: 500,
              display: 'flex',
            }}
          >
            {(isLicenseProcessing || isAssignmentProcessing) && (
              <ConfigProvider
                theme={{ token: { colorTextLightSolid: '#000' } }}
              >
                <Tooltip
                  title={tooltipTitle}
                  color="#fff"
                  key="#fff"
                  style={{ color: '#f00' }}
                  overlayInnerStyle={{ color: '#000' }}
                >
                  <InfoCircleOutlined style={{ marginRight: '5px' }} />
                </Tooltip>
              </ConfigProvider>
            )}
          </div>
          <span
            className="cursor-pointer"
            onClick={() => {
              if (!isLoading) {
                redirect();
              }
            }}
          >
            {getNameString(agentName)}
          </span>
        </>
      ) : (
        'error'
      )}
    </div>
  );
}

export default AssociatedAgent;
