import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Popover, Skeleton, message } from 'antd';
import { modalDesc, modalTitle } from '../organizationView/organizationUtils';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../../services/agency.service';
import AppliedFilter from './appliedFilter';
import NoSavedLists from '../../../../assets/images/SavedListBucket.svg';
import { RouteConstants } from '../../../../constants/routes.constants';
import cta from '../../../../assets/icons/expand-lists.svg';
import { useAuth } from '../../../../auth/authProvider';
import { useNavigate } from 'react-router';

interface SavedListProps {
  onBack: () => void;
}

export interface SavedListDetailsType {
  listName: string;
  id: string;
  hiearchyListFilters: any;
}

const SavedList: React.FC<SavedListProps> = ({ onBack }) => {
  const navigate = useNavigate();
  const [SavedListDetails, setSavedListDetails] = useState<
    SavedListDetailsType[]
  >([]);
  const [isFetching, setIsFetching] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [deleteList, setDeleteList] = useState<{
    loading: boolean;
    isOpen: SavedListDetailsType | null;
  }>({
    loading: false,
    isOpen: null,
  });
  const [cloneList, setCloneList] = useState<{
    loading: boolean;
    isOpen: SavedListDetailsType | null;
    title: string;
  }>({
    loading: false,
    isOpen: null,
    title: '',
  });

  const getAllSavedLists = async () => {
    try {
      setIsFetching(true);
      const bearerToken = await getAccessTokenSilently();
      const savedList = await AgencyService.getHiearchyListView(bearerToken);
      setSavedListDetails(savedList as SavedListDetailsType[]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getAllSavedLists();
  }, []);

  const onDeleteListById = async () => {
    try {
      setDeleteList((prev) => ({ ...prev, loading: true }));
      const bearerToken = await getAccessTokenSilently();
      const response = await AgencyService.deleteHiearchyListById(
        bearerToken,
        deleteList?.isOpen?.id!
      );
      setSavedListDetails((prev) =>
        prev.filter((d) => d.id !== deleteList?.isOpen?.id)
      );
      setDeleteList((prev) => ({ ...prev, loading: false, isOpen: null }));
      message?.success(response?.message);
    } catch (error) {
      console.error(error);
      setDeleteList((prev) => ({ ...prev, loading: false }));
    }
  };

  const onCloneList = async () => {
    try {
      setCloneList((prev) => ({ ...prev, loading: true }));
      const bearerToken = await getAccessTokenSilently();
      const response = await AgencyService.createHiearchyListView(bearerToken, {
        listName: cloneList?.title,
        hiearchyListFilters: cloneList?.isOpen?.hiearchyListFilters,
      });
      setSavedListDetails((prev) => [response, ...prev]);
      message.success(`${cloneList?.title} is created successfully`);
      setCloneList((prev) => ({
        ...prev,
        loading: false,
        title: '',
        isOpen: null,
      }));
    } catch (error: any) {
      console.error(error);
      message?.error(
        error?.response?.data?.message ||
          'Failed to clone list.Please Try Again.'
      );
      setCloneList((prev) => ({ ...prev, loading: false }));
    }
  };

  return (
    <div
      style={{
        padding: '12px 0',
        width: '100%',
      }}
    >
      <div className="figtree sub-heading">
        <ArrowLeftOutlined
          style={{ marginRight: 12 }}
          className="cursor-pointer"
          onClick={() => onBack()}
        />
        Saved Lists
      </div>
      {isFetching ? (
        <Skeleton />
      ) : SavedListDetails?.length === 0 ? (
        <div
          className="flex-col-center"
          style={{
            width: '100%',
            height: 'calc(100vh - 240px)',
          }}
        >
          <img src={NoSavedLists} alt="no-saved-listes" />
          <Button
            className="figtree"
            style={{
              background: 'var(--Secondary-Secondary, #001F45)',
              color: '#ffffff',
              fontSize: 14,
              lineHeight: '22px',
              fontWeight: 500,
              margin: '12px 0',
            }}
            onClick={() => onBack()}
          >
            <PlusOutlined /> Create Saved List
          </Button>
          <div className="sub-heading-small figtree">
            You haven’t created any view so far!
          </div>
          <div className="sub-heading-small figtree">
            Create your first view to see the list of producers
          </div>
        </div>
      ) : (
        <div
          style={{
            border: '1px solid #e6e6e6',
            borderRadius: 6,
            background: '#FFFFFF',
            marginTop: 16,
          }}
        >
          {SavedListDetails.map((data) => {
            return (
              <div
                style={{
                  padding: '16px 20px',
                  borderBottom: '1px solid #e6e6e6',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {' '}
                <div
                  onClick={() => {
                    navigate(
                      RouteConstants.agencyHierarchy.path + `?id=${data?.id}`
                    );
                  }}
                  className="cursor-pointer"
                >
                  {' '}
                  {data?.listName}
                </div>
                <Popover
                  placement="bottomRight"
                  title={null}
                  arrow={false}
                  content={
                    <div onClick={(e) => e.stopPropagation()}>
                      {['Clone', 'Delete']?.map((d) => {
                        return (
                          <div
                            style={{
                              padding: '4px 12px 4px 8px',
                              minWidth: '90px',
                              fontSize: 12,
                              lineHeight: '22px',
                              fontWeight: 400,
                            }}
                            onClick={() => {
                              if (d === 'Delete') {
                                setDeleteList((prev) => ({
                                  ...prev,
                                  isOpen: data,
                                }));
                              } else if (d === 'Clone') {
                                setCloneList((prev) => ({
                                  ...prev,
                                  isOpen: data,
                                }));
                              }
                            }}
                            className="cursor-pointer figtree"
                          >
                            {d}
                          </div>
                        );
                      })}
                    </div>
                  }
                >
                  <img
                    className="cursor-pointer"
                    src={cta}
                    alt="expand-lists"
                    style={{ marginRight: 12 }}
                  />
                </Popover>
              </div>
            );
          })}{' '}
        </div>
      )}
      <Modal
        width={900}
        title={
          <div
            style={{
              ...modalTitle,
            }}
          >
            Do you want to Delete this saved list?
          </div>
        }
        open={!!deleteList?.isOpen}
        onOk={onDeleteListById}
        confirmLoading={deleteList?.loading}
        closable={!deleteList?.loading}
        onCancel={() => setDeleteList({ isOpen: null, loading: false })}
        okText="Delete"
        okButtonProps={{
          style: {
            background: '#EB3131',
            width: 240,
          },
        }}
      >
        {' '}
        <div style={{ ...modalDesc }}>
          Lists that have been deleted once cannot be revoked again
        </div>
        <div style={{ marginBottom: 24 }}>
          <AppliedFilter
            appliedFilters={deleteList?.isOpen?.hiearchyListFilters || []}
          />
        </div>
      </Modal>
      <Modal
        title={
          <div
            style={{
              ...modalTitle,
            }}
          >
            Do you want to clone this saved list?
          </div>
        }
        width={900}
        open={!!cloneList?.isOpen}
        onOk={onCloneList}
        confirmLoading={cloneList?.loading}
        closable={!cloneList?.loading}
        onCancel={() =>
          setCloneList({
            isOpen: null,
            loading: false,
            title: '',
          })
        }
        okText="Clone"
        okButtonProps={{
          disabled: !cloneList.title,
          style: {
            background: !cloneList.title ? 'rgba(0, 31, 69, 0.7)' : '#001F45',
            width: 240,
            color: '#ffffff',
          },
        }}
      >
        <div style={{ ...modalDesc }}>
          Create a copy of the saved list that you’ve already created
        </div>
        <Input
          placeholder="Enter the List name"
          style={{ height: 28 }}
          onChange={(e) =>
            setCloneList((prev) => ({
              ...prev,
              title: e.target.value,
            }))
          }
        />
        <div style={{ marginBottom: 24 }}>
          <AppliedFilter
            appliedFilters={cloneList?.isOpen?.hiearchyListFilters || []}
            hideClose={true}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SavedList;
