import {
  FilterTitles,
  LEVEL_FILTER,
  LICENSE_FILTER,
  SelectedChip,
} from '../organizationView/organizationUtils';
import {
  getStateCodeFromName,
  getStateNameFromCode,
} from '../../../../utils/common.utils';

import React from 'react';

export interface filterType {
  condition: null | string;
  type: null | string[];
  match: null | string;
  value: null | string[];
}

const AppliedFilter: React.FC<{
  appliedFilters: filterType[];
  onClick?: (field: string, value: string, index: number) => void;
  hideClose?: boolean;
}> = ({ appliedFilters, onClick, hideClose }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        justifyContent: 'center',
        padding: '12px 0 6px',
      }}
    >
      {appliedFilters.map((d, i) => {
        return (
          <div style={{ display: 'flex', gap: 24 }} key={i}>
            <div style={{ flexBasis: '200px', flexGrow: 0 }}>
              <span
                style={{ gap: 8, alignItems: 'center', width: 200 }}
                className="font-12 flex figtree var(--color-primary) weight-400 line-height-16 no-wrap"
              >
                <span style={{ width: 32 }}>{i ? null : 'Type: '}</span>
                {d.type?.map((d) => (
                  <SelectedChip
                    value={d}
                    hideClose={hideClose}
                    onClick={(value) => {
                      if (onClick) {
                        onClick(FilterTitles.TYPE, value, i);
                      }
                    }}
                  />
                ))}
              </span>
            </div>
            <div style={{ flexBasis: '320px', flexGrow: 0 }}>
              <span className="font-12 figtree var(--color-primary) weight-400 line-height-16 no-wrap">
                <span
                  style={{
                    width: 60,
                    display: 'inline-block',
                  }}
                >
                  {i ? null : 'Condition: '}
                </span>{' '}
                {d?.match ? (
                  <SelectedChip value={d.match} hideClose={hideClose} />
                ) : null}
              </span>
            </div>
            <div
              style={{
                gap: 8,
                alignItems: 'flex-start',
                flexGrow: 1,
              }}
              className="font-12 flex figtree var(--color-primary) weight-400 line-height-16"
            >
              {' '}
              <span style={{ width: '40px', display: 'inline-block' }}>
                {i ? null : 'Value: '}
              </span>{' '}
              <div className="flex" style={{ gap: 8, flexWrap: 'wrap' }}>
                {' '}
                {d.value?.map((data) => (
                  <SelectedChip
                    hideClose={hideClose}
                    onClick={(value) => {
                      if (onClick) {
                        onClick(FilterTitles.VALUE, data, i);
                      }
                    }}
                    value={
                      LEVEL_FILTER.includes(d?.match!)
                        ? `Level ${data}`
                        : LICENSE_FILTER.includes(d?.match!)
                        ? getStateNameFromCode(data)
                        : data
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AppliedFilter;
