import {
  ActiveLicense,
  AssignmentOptions,
  IndividualOnboardAssignmentProps,
  StateLicenses,
} from './individualOnboardV2Type.type';
import { Button, Card, Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { LoaDetails, StateDetails } from '../../../../types/data/masterData';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../../services/agency.service';
import { AgentDetailsCard } from './individualOnboardDetails';
import { ApplicantType } from '../../../../enums/applicantType.enum';
import AssignmentLicenseClass from './assignmentLicenseClass';
import AssignmentStateTerritories from './assignmentStateTerritories';
import CustomTab from '../../../../components/common/customTab/customTab';
import EmptyState from '../../../../components/common/emptyState/emptyState';
import { LicensesService } from '../../../../services/licenses.service';
import { ResidencyType } from '../../../../enums/residencyType.enum';
import { adminStore } from '../../../../stores/admin.store';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { getStateNameFromCode } from '../../../../utils/common.utils';
import { individualOnboardStore } from '../../../../stores/individualOnboardStore';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../../../auth/authProvider';

const IndividualOnboardAssignments: React.FC<IndividualOnboardAssignmentProps> =
  observer(
    ({
      agentBioInfo,
      selectedItems,
      stateGroups,
      setSelectedItems,
      setStateGroups,
      isReview = false,
      selectedTerritory,
      setSelectedTerritory,
      selectedState,
      setSelectedState,
      selectedTab,
      setSelectedTab,
    }) => {
      const { getAccessTokenSilently } = useAuth();
      const [loading, setLoading] = useState(false);
      const [producerLicenseLoader, setProducerLicenseLoader] = useState(false);

      const [searchAssignmentQuery, setSearchAssignmentQuery] = useState('');
      const [searchStateQuery, setSearchStateQuery] = useState('');
      const [masterData, setMasterData] = useState<StateDetails[]>([]);
      const [producerActiveLicenses, setProducerActiveLicenses] = useState<
        ActiveLicense[]
      >([]);

      const fetchStateGroups = async (activeLicenses: ActiveLicense[]) => {
        if (!adminStore.agency) return;
        setLoading(true);
        try {
          const token = await getAccessTokenSilently();
          const response = await AgencyService.getTerritories(token);
          if (response && response.data) {
            const data = response.data;
            data.sort((a, b) => a.name.localeCompare(b.name));
            data.forEach((item) => {
              item.stateLicenseConfigs.sort((a, b) => {
                const stateNameA = getStateNameFromCode(a.stateCode);
                const stateNameB = getStateNameFromCode(b.stateCode);
                return stateNameA.localeCompare(stateNameB);
              });
            });

            const masterData = [
              ...appStateInfoStore.getLicenseConfigsMasterData(),
            ];
            const updatedActiveLicenseInTerritories = data.map((d) => {
              const stateLicenseConfigs = d.stateLicenseConfigs.map((data) => {
                const isActiveState = activeLicenses.find(
                  (d) => d.stateCode === data.stateCode
                );

                if (!isActiveState) return data;
                const masterDataState = masterData.find(
                  (d) => d.stateCode === data.stateCode
                );
                const updatedLicenseConfigs = data.licenseConfigs?.map((d) => {
                  if (d.lcCode !== isActiveState.licenseClassCode) return d;

                  const masterDataLcs = masterDataState?.licenseClasses?.find(
                    (data) => data.lcCode === isActiveState.licenseClassCode
                  );

                  const updatedConfigs: string[] = [];
                  const activeLoas: string[] = [];

                  isActiveState?.loaDetails?.forEach((data) => {
                    const activeLoa = masterDataLcs?.loaData?.find(
                      (d) => d.loaCode === data.loaCode
                    );
                    if (activeLoa) {
                      activeLoas.push(activeLoa.loaCode);
                    }
                    if (activeLoa && activeLoa?.referenceLicenseConfig) {
                      updatedConfigs.push(activeLoa?.referenceLicenseConfig);
                    }
                  });

                  const loas = masterDataLcs?.loaData
                    ?.filter((d) => {
                      if (
                        d?.referenceLicenseConfig &&
                        updatedConfigs.includes(d?.id!)
                      )
                        return false;
                      return activeLoas.includes(d.loaCode) || !d.isRetired;
                    })
                    ?.map((d) => ({
                      id: d.id,
                      isRetired: d.isRetired,
                      loaCode: d.loaCode,
                      loaName: d.loa,
                      referenceLicenseConfig: d.referenceLicenseConfig,
                      residencyType: d.residencyType,
                    }));

                  return { ...d, loaConfigs: loas! };
                });
                return {
                  ...data,
                  licenseConfigs: updatedLicenseConfigs,
                };
              });
              return { ...d, stateLicenseConfigs };
            });

            setStateGroups(
              updatedActiveLicenseInTerritories.map((d) => ({
                ...d,
                isSelected: false,
              }))
            );
            setSelectedTerritory(data?.[0]?.name || '');
            setSelectedState(
              getStateNameFromCode(
                data?.[0]?.stateLicenseConfigs?.[0]?.stateCode
              )
            );
          } else {
            setStateGroups([]);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      const fetchProducerActiveLicense = async () => {
        if (!agentBioInfo.producerId) return;
        setProducerLicenseLoader(true);
        try {
          const token = await getAccessTokenSilently();
          const response = await LicensesService.getActiveLicenseForProducers(
            token,
            agentBioInfo.producerId
          );
          if (response?.data?.data) {
            setProducerActiveLicenses(response?.data?.data);
            individualOnboardStore.setProducerActiveLicenses(
              response.data.data
            );
          }

          const activeLicenses =
            (response?.data?.data as ActiveLicense[]) || [];

          const masterData = [
            ...appStateInfoStore.getLicenseConfigsMasterData(),
          ];

          masterData.sort((a, b) => {
            const nameA = getStateNameFromCode(a.stateCode);
            const nameB = getStateNameFromCode(b.stateCode);
            return nameA.localeCompare(nameB);
          });

          const updatedMasterData = masterData.map((d) => {
            const licenses = d?.licenseClasses?.map((lc) => {
              const activeLicense = activeLicenses.find(
                (al) =>
                  al.stateCode === d.stateCode &&
                  lc.lcCode === al?.licenseClassCode
              );
              const updatedConfigs: string[] = [];
              const activeLoas: string[] = [];
              lc.loaData?.forEach((d) => {
                const activeLoa = activeLicense?.loaDetails?.find(
                  (loa) => loa.loaCode === d?.loaCode
                );
                if (activeLoa) {
                  activeLoas.push(activeLoa.loaCode);
                }
                if (activeLoa && d?.referenceLicenseConfig) {
                  updatedConfigs.push(d?.referenceLicenseConfig);
                }
              });
              const loas = lc.loaData?.filter((d) => {
                if (
                  d?.referenceLicenseConfig &&
                  updatedConfigs.includes(d?.id!)
                )
                  return false;
                return activeLoas.includes(d.loaCode) || !d.isRetired;
              });
              return {
                ...lc,
                loaData: loas,
              };
            });
            return {
              ...d,
              licenseClasses: licenses,
            };
          });

          fetchStateGroups(response?.data?.data);

          setMasterData(updatedMasterData);
        } catch (error) {
          individualOnboardStore.setProducerActiveLicenses([]);
          console.error(error);
        } finally {
          setProducerLicenseLoader(false);
        }
      };

      useEffect(() => {
        fetchProducerActiveLicense();
      }, []);

      const getActiveLicenseStates = () => {
        return producerActiveLicenses?.map((al) => al.stateCode);
      };

      const getFilteredStateGroupItems = () => {
        return stateGroups
          .filter(
            (d) =>
              d.name
                ?.toLowerCase()
                ?.trim()
                ?.includes(searchAssignmentQuery?.trim()?.toLowerCase()) &&
              // in review mode, need to show only selected territories
              (isReview ? d.isSelected : true)
          )
          .map((d) => d.name);
      };

      const areAllLicenseClassesResidentTypeRForhomeState = (
        currentStateCode: string
      ): boolean => {
        const homeState = agentBioInfo?.agentHomeStateAddresses?.[0]?.stateCode;
        const licenses = masterData
          ?.filter(
            (data) => getStateNameFromCode(data.stateCode) === currentStateCode
          )
          ?.flatMap((d) => d.licenseClasses);
        return (
          licenses.length > 0 &&
          ![homeState].includes(currentStateCode) &&
          licenses.every((licenseClass) => licenseClass.residencyType === 'R')
        );
      };

      const getFilteredStateItems = () => {
        if (selectedTab === AssignmentOptions.TERRITORIES) {
          return stateGroups
            ?.filter((d) => d.name === selectedTerritory)?.[0]
            ?.stateLicenseConfigs?.filter((d) =>
              getStateNameFromCode(d.stateCode)
                ?.toLowerCase()
                ?.trim()
                ?.includes(searchStateQuery?.trim()?.toLowerCase())
            )
            ?.map((d) => {
              return {
                code: d.stateCode,
                name: getStateNameFromCode(d.stateCode),
              };
            });
        }

        return masterData
          ?.map((data) => {
            const isDisabled = areAllLicenseClassesResidentTypeRForhomeState(
              data.stateCode
            );
            return {
              code: data.stateCode,
              name: getStateNameFromCode(data.stateCode),
              isDisabled,
              tooltipText: isDisabled
                ? 'This state cannot be selected as there are no matching license classes available.'
                : null,
            };
          })
          ?.filter(
            (data) =>
              data.name
                ?.toLowerCase()
                ?.trim()
                ?.includes(searchStateQuery?.trim()?.toLowerCase()) &&
              // in review mode, need to show only selected states
              (isReview
                ? selectedItems.map((data) => data.name).includes(data.name)
                : true)
          );
      };

      const getLicensedData = () => {
        // for territories
        const homeState = agentBioInfo?.agentHomeStateAddresses?.[0]?.stateCode;
        if (selectedTab === AssignmentOptions.TERRITORIES) {
          const selectedStateLicenseDetails =
            stateGroups
              ?.filter((d) => d.name === selectedTerritory)?.[0]
              // state has to be filter based on the input value in the search
              ?.stateLicenseConfigs?.filter(
                (d) =>
                  getStateNameFromCode(d.stateCode)
                    ?.toLowerCase()
                    ?.trim()
                    ?.includes(searchStateQuery?.trim()?.toLowerCase()) &&
                  selectedState === getStateNameFromCode(d.stateCode)
              )
              ?.map((d) => {
                return {
                  ...d,
                  licenseConfigs: d.licenseConfigs
                    ?.filter((data) => {
                      if (!isReview) return true;
                      const residencyType =
                        data?.residencyType ||
                        data?.loaConfigs?.[0]?.residencyType;
                      const disableTheStateCardForRLicenses =
                        areAllLicenseClassesResidentTypeRForhomeState(
                          d.stateCode
                        );
                      const isDisabled =
                        disableTheStateCardForRLicenses ||
                        (!homeState?.includes(d.stateCode) &&
                          residencyType === ResidencyType.Resident) ||
                        (homeState?.includes(d.stateCode) &&
                          residencyType === ResidencyType.NonResident)
                          ? true
                          : false;
                      return !isDisabled;
                    })
                    ?.map((d) => ({
                      ...d,
                      licenseClass: d.lcName,
                      loaData: d.loaConfigs,
                    })),
                };
              }) || [];

          return selectedStateLicenseDetails?.[0] || [];
        }

        // if its not territory show all the states from master data
        const filteredLicenseConfgs = masterData
          ?.filter(
            (data) => getStateNameFromCode(data.stateCode) === selectedState
          )
          ?.map((d) => ({
            licenseConfigs: d.licenseClasses?.filter((d1) =>
              !isReview
                ? true
                : Object?.keys(
                    selectedItems?.filter(
                      (data) =>
                        data?.name === getStateNameFromCode(d?.stateCode)
                    )?.[0] || {}
                  )?.includes(d1.lcCode)
            ),
            stateCode: d.stateCode,
          }));

        const stateCode = filteredLicenseConfgs?.[0]?.stateCode;

        return {
          licenseConfigs: filteredLicenseConfgs?.[0]?.licenseConfigs,
          stateCode,
        };
      };

      //  it takes true for right click and false for left click
      const handleClick = (type: boolean) => {
        const isRight = type ? 1 : -1;
        const states = getFilteredStateItems();
        const currentIndex = states.findIndex(
          (state) => state?.name === selectedState
        );
        const currentStateCode = states?.filter(
          (state) => state.name === states[currentIndex + isRight]?.name
        )?.[0]?.code;
        if (currentStateCode) {
          setSelectedState(getStateNameFromCode(currentStateCode));
        }
      };

      const handleCheckboxChange = (
        lcCode: string,
        loaName: string,
        loaDetails: { loaId: string; id: string } | null,
        checked: boolean,
        stateName: string,
        lcName: string,
        allLoaDetails: LoaDetails[] | null
      ) => {
        // while checked
        if (checked) {
          const isStateChecked = selectedItems
            .map((data) => data.name)
            ?.includes(stateName);
          // state is already selected
          if (isStateChecked) {
            setSelectedItems((prev) => {
              return prev.map((data) => {
                if (data.name === stateName) {
                  // add loas only if the handle change is triggered by loa
                  const loas = loaDetails?.loaId
                    ? [
                        ...(data?.[lcCode]?.loas || []),
                        {
                          name: loaName,
                          loaCode: loaDetails?.loaId,
                          id: loaDetails?.id,
                        },
                      ]
                    : allLoaDetails?.map((d) => ({
                        // if lc selected
                        name: d?.loaName || '',
                        loaCode: d?.loaCode,
                        id: d.id,
                      })) || [];
                  return {
                    ...data,
                    [lcCode]: {
                      name: lcName,
                      loas,
                    },
                  };
                }
                return data;
              });
            });
          } else {
            // if state is not selected,
            // 1. add state and lc and loa if loa is selected
            // 2. add state and lc if lc is selected
            const loas = loaDetails?.loaId
              ? [
                  {
                    name: loaName,
                    loaCode: loaDetails?.loaId,
                    id: loaDetails?.id,
                  },
                ]
              : allLoaDetails?.map((d) => ({
                  // if lc selected
                  name: d?.loaName || '',
                  loaCode: d?.loaCode,
                  id: d.id,
                })) || [];
            setSelectedItems((prev) => [
              ...prev,
              {
                name: stateName,
                [lcCode]: {
                  name: lcName,
                  loas,
                },
              } as StateLicenses,
            ]);
          }
        } else {
          // while uncheck
          setSelectedItems((prev) => {
            return prev.map((data) => {
              if (data.name === stateName) {
                // if loa uncheck
                if (loaDetails?.loaId) {
                  data = {
                    ...data,
                    [lcCode]: {
                      ...(data?.[lcCode] || []),
                      //  then filter the loa alone
                      loas: data?.[lcCode]?.loas?.filter(
                        (d) => d.loaCode !== loaDetails.loaId
                      ),
                    },
                  };
                  return { ...data };
                }
                //  if else uncheck , delete the lc with loa details
                delete data?.[lcCode];
                return {
                  ...data,
                };
              }
              return data;
            });
          });
        }
      };

      return (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              gap: 24,
            }}
          >
            <Card
              style={{ width: 'fit-content' }}
              className="producer-onboarding-cards"
            >
              <AgentDetailsCard agentBioInfo={agentBioInfo} showNpn={false} />
            </Card>
            <CustomTab
              selectedTab={selectedTab}
              className="individual-onboarding"
              tabList={Object.values(AssignmentOptions)}
              onSelect={(value) => {
                if (value === selectedTab) return;
                if (value === AssignmentOptions.TERRITORIES) {
                  if (!isReview) {
                    setSelectedState(
                      getStateNameFromCode(
                        stateGroups?.filter(
                          (d) => d.name === selectedTerritory
                        )?.[0]?.stateLicenseConfigs?.[0]?.stateCode
                      )
                    );
                  } else {
                    // in review mode, set the first state of the selected territories
                    setSelectedState(
                      getStateNameFromCode(
                        stateGroups.filter(
                          (data) =>
                            data.isSelected && data.name === selectedTerritory
                        )?.[0]?.stateLicenseConfigs?.[0]?.stateCode
                      )
                    );
                  }
                }
                if (value === AssignmentOptions.STATE) {
                  if (!isReview) {
                    setSelectedState(
                      getStateNameFromCode(masterData?.[0]?.stateCode)
                    );
                  } else {
                    // in review mode, set the first state of the selected states
                    setSelectedState(
                      getStateNameFromCode(selectedItems?.[0]?.name)
                    );
                  }
                }
                setSelectedTab(value);
              }}
              styles={{
                minWidth: 300,
              }}
            />
          </div>
          <Card
            style={{ marginTop: 12, height: 'calc(100vh - 228px)' }}
            className="individual-onboard-assignment-card"
          >
            {loading || producerLicenseLoader ? (
              <div
                style={{
                  display: 'grid',
                  height: '100%',
                  placeContent: 'center',
                }}
              >
                <Spin />
              </div>
            ) : (selectedTab === AssignmentOptions.TERRITORIES && // no territories selected
                (!stateGroups.length ||
                  (isReview &&
                    !stateGroups.some((data) => data.isSelected)))) ||
              // no state selected
              (selectedTab === AssignmentOptions.STATE &&
                !selectedItems?.length &&
                isReview) ? (
              <div
                style={{
                  display: 'grid',
                  height: '100%',
                  placeContent: 'center',
                }}
              >
                <EmptyState
                  image="empty data"
                  heading=""
                  width="100%"
                  content={`No ${
                    selectedTab === AssignmentOptions.TERRITORIES
                      ? 'Territories'
                      : 'States'
                  } ${isReview ? 'Selected' : 'Found'}`}
                />
              </div>
            ) : (
              <div style={{ display: 'flex', gap: 18, minHeight: '100%' }}>
                {/* territories */}
                {selectedTab === AssignmentOptions.TERRITORIES ? (
                  <AssignmentStateTerritories
                    isCheckboxNeeded={!isReview}
                    searchQuery={searchAssignmentQuery}
                    setSearchQuery={setSearchAssignmentQuery}
                    selectedCount={stateGroups.filter((d) => d.isSelected)}
                    placeHolder="Territories"
                    currentSelected={selectedTerritory}
                    filteredItems={getFilteredStateGroupItems() || []}
                    onCheck={(e, t) => {
                      let selectedItem = selectedItems;
                      setStateGroups((prev) => {
                        return prev.map((d) => {
                          if (d.name === t) {
                            // while selecting the state group, remove the states from state selection if its already selected
                            if (e) {
                              const states: string[] = [];
                              d.stateLicenseConfigs.forEach((d) => {
                                const stateName = getStateNameFromCode(
                                  d.stateCode
                                );
                                states.push(stateName);
                              });
                              selectedItem = selectedItems.filter(
                                (d) => !states.includes(d.name)
                              );
                            }
                            return { ...d, isSelected: e };
                          }
                          return d;
                        });
                      });
                      setSelectedItems(selectedItem);
                    }}
                    onSelect={(e) => {
                      setSelectedTerritory(e as string);
                      setSelectedState(
                        getStateNameFromCode(
                          stateGroups.filter((d) => d.name === e)?.[0]
                            ?.stateLicenseConfigs?.[0]?.stateCode
                        )
                      );
                    }}
                    type="Territories"
                    activeState={getActiveLicenseStates()}
                  />
                ) : null}
                {/* states */}
                <AssignmentStateTerritories
                  searchQuery={searchStateQuery}
                  setSearchQuery={setSearchStateQuery}
                  isCheckboxNeeded={!isReview}
                  selectedCount={
                    selectedTab === AssignmentOptions.TERRITORIES
                      ? []
                      : selectedItems
                  }
                  disabledItems={
                    // for disabling the states if any territory selected which contains the current state
                    selectedTab === AssignmentOptions.TERRITORIES
                      ? []
                      : stateGroups
                          .filter((d) => d.isSelected)
                          ?.flatMap((d) => d.stateLicenseConfigs)
                          ?.map((d) => getStateNameFromCode(d.stateCode))
                  }
                  placeHolder="States"
                  currentSelected={selectedState}
                  filteredItems={getFilteredStateItems() || []}
                  onCheck={(e, selectedState) => {
                    // selection of states not applicable to territories
                    if (selectedTab === AssignmentOptions.TERRITORIES) return;

                    if (e) {
                      setSelectedItems((prev) => [
                        ...prev,
                        { name: selectedState } as StateLicenses,
                      ]);
                    } else {
                      setSelectedItems((prev) =>
                        prev.filter((d) => d.name !== selectedState)
                      );
                    }
                  }}
                  onSelect={(e) => {
                    setSelectedState(e);
                  }}
                  type="State"
                  {...(selectedTab === AssignmentOptions.TERRITORIES
                    ? {
                        checkBoxProps: {
                          disabled: true,
                          checked: true,
                        },
                      }
                    : {})}
                  activeState={getActiveLicenseStates()}
                />
                <div
                  style={{
                    width: '100%',
                    borderRadius: '6px',
                    border: '1px solid #D6E4EB',
                    padding: '30px',
                    gap: '34px',
                    backgroundColor: '#FFFFFF',
                    boxSizing: 'border-box',
                    overflowY: 'auto',
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '56px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%', // Hug content
                        height: '100%', // Hug content
                        marginBottom: '20px',
                      }}
                    >
                      <p
                        style={{
                          fontFamily: 'Poppins, sans-serif',
                          fontWeight: 500,
                          fontSize: '12px',
                          color: '#07212D',
                          lineHeight: '20px',
                          margin: 0,
                        }}
                      >
                        Current State:
                      </p>
                      <p
                        style={{
                          fontFamily: 'Poppins, sans-serif',
                          fontWeight: 500,
                          fontSize: '26px',
                          color: '#001F45',
                          marginTop: '6px',
                          lineHeight: '28px',
                        }}
                      >
                        {selectedState}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        marginBottom: '20px',
                      }}
                    >
                      <Button
                        icon={<LeftOutlined />}
                        onClick={() => handleClick(false)}
                        disabled={
                          selectedState === getFilteredStateItems()?.[0]?.name
                        }
                        style={{
                          width: '36px',
                          height: '36px',
                          borderRadius: '6px',
                          backgroundColor: '#E8EBEE',
                          padding: '0px 1px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginLeft: '20px',
                        }}
                      />
                      <Button
                        icon={<RightOutlined style={{ color: 'white' }} />}
                        onClick={() => handleClick(true)}
                        disabled={
                          selectedState ===
                          getFilteredStateItems()?.[
                            getFilteredStateItems()?.length - 1
                          ]?.name
                        }
                        style={{
                          width: '36px',
                          height: '36px',
                          borderRadius: '6px',
                          backgroundColor: '#00A6FB',
                          padding: '0px 1px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      overflowY: 'auto',
                    }}
                  >
                    <Spin spinning={loading || producerLicenseLoader}>
                      {getLicensedData()?.licenseConfigs?.map((d) => {
                        const stateCode = getLicensedData()?.stateCode;
                        const stateName = getStateNameFromCode(stateCode);
                        const isSelectedInTerritory = stateGroups
                          .filter((d) => d.isSelected)
                          ?.flatMap((d) => d.stateLicenseConfigs)
                          ?.map((d) => getStateNameFromCode(d.stateCode))
                          ?.includes(stateName);
                        const selectedLoaCodes =
                          selectedItems
                            ?.filter((d) => d.name === stateName)?.[0]
                            ?.[d.lcCode]?.loas?.map((d) => d.loaCode) || [];
                        const activeLicensepresent =
                          producerActiveLicenses?.find(
                            (activeLicense) =>
                              activeLicense?.stateCode === stateCode &&
                              activeLicense.licenseClassCode === d.lcCode
                          );
                        const disableTheStateCardForRLicenses =
                          areAllLicenseClassesResidentTypeRForhomeState(
                            stateCode
                          );
                        const homeState =
                          agentBioInfo?.agentHomeStateAddresses?.[0]?.stateCode;

                        const residencyType =
                          d?.residencyType || d?.loaData?.[0]?.residencyType;
                        const isDisabled = disableTheStateCardForRLicenses
                          ? 'This state cannot be selected as there are no matching license classes available.'
                          : !homeState?.includes(stateCode) &&
                            residencyType === ResidencyType.Resident
                          ? 'This configuration cannot be applied to the producer as it is designated for residents'
                          : homeState?.includes(stateCode) &&
                            residencyType === ResidencyType.NonResident
                          ? 'This configuration cannot be applied to the producer as it is designated for non-residents'
                          : null;
                        return (
                          <AssignmentLicenseClass
                            residencyType={d.residencyType}
                            licenseClassCode={d.lcCode}
                            isLicenseChecked={
                              selectedTab === AssignmentOptions.TERRITORIES
                                ? !isDisabled
                                : !!selectedItems?.filter(
                                    (d) => d.name === stateName
                                  )?.[0]?.[d.lcCode] ||
                                  (isSelectedInTerritory && !isDisabled)
                            }
                            isCheckboxNeeded={!isReview} // In Review Mode
                            disableCheckBox={
                              selectedTab === AssignmentOptions.TERRITORIES ||
                              isSelectedInTerritory || // if territories disable
                              !!isDisabled
                            }
                            text={`${d.licenseClass} (${d.lcCode})`}
                            stateCode={stateCode}
                            tooltipText={isDisabled}
                            LoaDetails={
                              selectedTab === AssignmentOptions.TERRITORIES ||
                              !isReview
                                ? d.loaData
                                : d.loaData.filter((d) =>
                                    selectedLoaCodes.includes(d.loaCode)
                                  )
                            }
                            handleCheckboxChange={(
                              lc,
                              loaName,
                              loa,
                              isChecked
                            ) =>
                              handleCheckboxChange(
                                lc,
                                loaName,
                                loa,
                                isChecked,
                                stateName,
                                d.licenseClass,
                                !loa ? d.loaData : null
                              )
                            }
                            selectedIds={
                              selectedTab === AssignmentOptions.TERRITORIES ||
                              isSelectedInTerritory
                                ? isDisabled
                                  ? []
                                  : d.loaData.map((d) => d.loaCode)
                                : selectedLoaCodes
                            }
                            activeLicense={
                              activeLicensepresent && activeLicensepresent
                            }
                          />
                        );
                      })}
                    </Spin>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </>
      );
    }
  );

export default IndividualOnboardAssignments;
