import AllLicensesAgencyList, { collapseItems } from './allLicensesAgencyList';
import {
  AutoComplete,
  Button,
  Checkbox,
  Input,
  Modal,
  Popover,
  Tooltip,
  message,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../../../types/common/filters.type';
import {
  FilterOutlined,
  GroupOutlined,
  MoreOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import axios, { CancelTokenSource } from 'axios';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../../../utils/search.utils';
import { useEffect, useRef, useState } from 'react';

import { ActionableLicenseResponseError } from '../../../../../types/response/actionable-license.type';
import AllLicensesAgencyMap from './agencyMap';
import { AllLicensesAgencyResponseType } from '../../../../../types/request/agency/allLicenses/allLicenses.type';
import ApplyLicenseErrorModal from '../../../../../components/actionableLicenseModal/applyLicenseErrorModal';
import CustomTab from '../../../../../components/common/customTab/customTab';
import EmptyState from '../../../../../components/common/emptyState/emptyState';
import { FilterType } from '../../../../../enums/filterType.enum';
import { IdConstants } from '../../../../../constants/id.constants';
import { LicensesService } from '../../../../../services/licenses.service';
import { PageConstants } from '../../../../../constants/page.constants';
import { RenderFilterChip } from '../../../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../../../enums/roles.enum';
import { RouteConstants } from '../../../../../constants/routes.constants';
import { SEARCH_REGEX } from '../../../../../constants/regex.constants';
import SimpleFilter from '../../../../../components/common/simpleFilter/simpleFilter';
import { Spin } from 'antd';
import { cancelPreviousRequest } from '../../../../../utils/api.utils';
import { isEmpty } from 'lodash';
import { isXDaysOld } from '../../../../../utils/date.utils';
import { setFilterGroups } from '../../../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useQueryState } from '../../../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../../../auth/useRoles';

export const listColors = [
  {
    type: ['info', 'pending'],
    borderColor: 'rgba(248, 215, 154, 1)',
    color: 'rgba(252, 239, 207, 1)',
  },
  {
    type: ['success', 'active'],
    borderColor: 'rgba(49, 235, 145, 1)',
    color: 'rgba(152, 245, 200, 0.5)',
  },
  {
    type: ['error', 'expired', 'inactive'],
    borderColor: 'rgba(235, 49, 49, 1)',
    color: 'rgba(245, 152, 152, 0.5)',
  },
  {
    type: ['missing'],
    borderColor: 'rgba(255, 201, 66, 1)',
    color: 'rgba(252, 239, 207, 1)',
  },
  {
    type: ['warning'],
    borderColor: '#F8D79A',
    color: '#FCEFCF',
  },
];

const { Search } = Input;
const AGENCY_LICENSE_VIEW = {
  LIST: 'List',
  MAP: 'Map',
};

function AllLicensesAgencyTab() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(AGENCY_LICENSE_VIEW.LIST);
  const popOverRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [refreshModal, setRefreshModal] = useState({
    isOpen: false,
    loading: false,
    showAgain: false,
    checkBox: false,
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [collapseKeys, setCollapseKeys] = useState<string[] | string>([]);
  const [requestBody, setRequestBody] = useState<Object>({});
  const [searchText, setSearchText] = useState<string>('');
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [queryText, setQueryText] = useQueryState('searchAll');
  const [allLicenses, setAllLicenses] =
    useState<AllLicensesAgencyResponseType | null>(null);
  const [isRefreshable, setIsRefreshable] = useState(false);
  const [
    applyLicenseErrorModalVisibility,
    setApplyLicenseErrorModalVisibility,
  ] = useState<boolean>(false);
  const [errors, setErrors] = useState<ActionableLicenseResponseError[]>([]);
  const [popUpOpen, setPopUpOpen] = useState(false);

  // simple filters
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [filterInfoLoading, setFilterInfoLoading] = useState<boolean>(false);
  const [controlledFilters, setControlledFilters] = useState([]);
  const [searchType, setSearchType] = useState('');
  const [displaySearchType, setDisplaySearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);

  //
  const { getAccessTokenSilently, bearerToken } = useAuth();

  const onCollapseChange = (key: string | string[]) => {
    setCollapseKeys(key);
  };

  const fetchFiltersInfoForAllLicenses = async () => {
    try {
      setFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any =
          await LicensesService.getFilterInfoForFindAllLicensesMapListAgencyTab(
            token
          );
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.ALL_AGENCY_LICENSES
          )
        );
        setControlledFilters(response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setFilterInfoLoading(false);
    }
  };

  useEffect(() => {
    fetchFiltersInfoForAllLicenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllLicenses = async (searchText: string) => {
    if (!bearerToken) return;
    try {
      if (
        (searchText || Object.keys(requestBody)?.length) &&
        allLicenses?.agencyLicenses?.length
      ) {
        setSearchLoading(true);
      } else {
        setLoading(true);
      }
      if (cancelToken) {
        cancelPreviousRequest(cancelToken);
      }
      const cancelTokenSource = axios.CancelToken.source();
      setCancelToken(cancelTokenSource);
      const allLicense: AllLicensesAgencyResponseType =
        await LicensesService.getAgencyLicensesForList_Map(
          bearerToken,
          cancelTokenSource,
          searchText,
          searchType,
          requestBody
        );
      setAllLicenses(allLicense);
      setSearchLoading(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      if (!axios.isCancel(error)) {
        setSearchLoading(false);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchAllLicenses(queryText || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestBody, bearerToken, queryText, searchType]);

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const items = collapseItems(allLicenses) || [];

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const refreshLicenses = async () => {
    if (!bearerToken) return;

    try {
      setRefreshModal((prev) => ({ ...prev, loading: true }));
      if (refreshModal.checkBox) {
        await LicensesService.refreshAgencyLicensesFlag(bearerToken);
      }
      await LicensesService.refreshAgencyLicenses(bearerToken);
      setRefreshModal((prev) => ({
        ...prev,
        loading: false,
        isOpen: false,
        showAgain: true,
        checkBox: false,
      }));
      fetchAllLicenses(queryText || '');
    } catch (error: any) {
      console.error(error);
      message.error(
        error?.response?.data?.error?.message ||
          'Failed to refresh licenses. Please try again later'
      );
    } finally {
      setRefreshModal((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (allLicenses?.dontShowAgainLicenseRefresh) {
      setRefreshModal((prev) => ({
        ...prev,
        showAgain: allLicenses?.dontShowAgainLicenseRefresh,
      }));
    }
    if (!allLicenses?.licensesLastUpdatedAt) {
      setIsRefreshable(true);
    } else {
      const isRefreshAllowed = isXDaysOld(
        allLicenses?.licensesLastUpdatedAt,
        1
      );
      setIsRefreshable(isRefreshAllowed);
    }
  }, [allLicenses]);

  useEffect(() => {
    if (refreshModal.isOpen) {
      setPopUpOpen(false);
    }
  }, [refreshModal.isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popOverRef.current &&
        !popOverRef.current.contains(event.target as Node)
      ) {
        setPopUpOpen(false);
      }
    };

    if (popUpOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popUpOpen]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
    }
  };

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  return (
    <div
      className="my-5 override-icon-size agency-all-licenses"
      id="agency-all-cards"
      style={{
        width: '100%',
      }}
    >
      <div className="flex justify-between items-center" style={{ gap: 48 }}>
        <div className="tabs">
          <CustomTab
            selectedTab={selectedTab}
            tabList={Object.values(AGENCY_LICENSE_VIEW)}
            onSelect={(value) => {
              if (value === selectedTab) return;
              setSelectedTab(value);
              setQueryText('');
              setSelectedFilters({ data: {} });
              if (queryText) {
                setLoading(true);
                fetchAllLicenses('');
              }
              if (Object.keys(requestBody || {}).length) {
                setRequestBody({});
              }
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 12,
            marginRight: 24,
          }}
        >
          {selectedTab === AGENCY_LICENSE_VIEW.LIST ? (
            <div
              style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}
              className="custom-autocomplete"
            >
              <AutoComplete
                options={options}
                style={{ width: '100%', height: '36px' }}
                disabled={
                  loading ||
                  (!queryText && !allLicenses?.agencyLicenses?.length)
                }
                value={getSearchDisplayText(
                  searchType,
                  displaySearchType,
                  searchText
                )}
                onSelect={handleOnSelect}
                onChange={handleOnChange}
              >
                <Search
                  id={IdConstants.SEARCH_INPUT + '-agency-all-licenses-tab'}
                  placeholder="Search Licenses by State, LOAs, License Class, LOA Code or License Class Code"
                  style={{
                    width: '100%',
                    minWidth: '311px',
                    height: 36,
                  }}
                  onSearch={handleOnSearch}
                />
              </AutoComplete>
              <Button
                id={IdConstants.AGENCY.ALL_LICENSES.EXPAND_ALL}
                disabled={loading || !allLicenses}
                style={{ height: 36, minWidth: 126 }}
                onClick={() => {
                  setCollapseKeys((prev) => {
                    if (prev.length === items?.length) return [];
                    return items?.map((data) => data.key) as string[];
                  });
                }}
              >
                {collapseKeys?.length === items?.length
                  ? 'Collapse All'
                  : 'Expand All'}
              </Button>
              <Button
                id={IdConstants.FILTERS_BUTTON}
                loading={filterInfoLoading}
                icon={<FilterOutlined />}
                style={{
                  background: 'var(--hint-primary-color)',
                  color: 'white',
                  marginRight: 12,
                  height: 36,
                }}
                onClick={() => {
                  setIsFilterVisible(true);
                }}
                disabled={loading || filterInfoLoading}
              >
                Filters
              </Button>{' '}
            </div>
          ) : null}
          <Popover
            placement="bottomRight"
            open={popUpOpen}
            // {...(loading || refreshModal.isOpen ? { open: false } : {})}
            content={
              <div
                ref={popOverRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: 150,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onMouseLeave={(e) => {
                  setPopUpOpen(false);
                }}
              >
                <p
                  className="flex-center cursor-pointer"
                  onClick={() =>
                    navigate(RouteConstants.myAgencyManageAssignments.path)
                  }
                >
                  <GroupOutlined style={{ marginRight: 12 }} />
                  Manage States
                </p>
                <Tooltip
                  title={
                    isRefreshable
                      ? null
                      : 'Licenses that have been refreshed once cannot be refreshed again within 24 hours.'
                  }
                >
                  <p
                    className="flex-center cursor-pointer"
                    onClick={() => {
                      if (!isRefreshable) return;
                      if (!refreshModal.showAgain) {
                        setRefreshModal((prev) => ({ ...prev, isOpen: true }));
                      } else {
                        refreshLicenses();
                      }
                    }}
                    style={{
                      cursor:
                        isRefreshable && !refreshModal.loading
                          ? 'pointer'
                          : 'not-allowed',
                      opacity: !isRefreshable ? '50%' : '100%',
                    }}
                  >
                    {refreshModal.loading ? (
                      <Spin size="small" style={{ marginRight: 12 }} />
                    ) : (
                      <SyncOutlined style={{ marginRight: 12 }} />
                    )}
                    Refresh Licenses
                  </p>
                </Tooltip>
              </div>
            }
          >
            <MoreOutlined
              id="agency-all-licenses-popover-cta"
              className={`${!loading ? 'cursor-pointer ' : ''} outlined`}
              size={24}
              onClick={() => {
                if (!loading && !refreshModal.isOpen) {
                  setPopUpOpen(true);
                }
              }}
              onMouseEnter={() => {
                if (!loading && !refreshModal.isOpen) {
                  setPopUpOpen(true);
                }
              }}
              onMouseLeave={(e) => {
                if (!popOverRef.current) {
                  setPopUpOpen(false);
                }
              }}
            />
          </Popover>
        </div>
      </div>
      <div style={{ marginTop: 16 }}>
        <RenderFilterChip
          {...{
            selectedFilters,
            removeFilter,
            clearFilters,
            pageName: 'all-licenses-agency-tab',
          }}
        />
      </div>
      {loading ? (
        <div
          style={{ display: 'grid', placeContent: 'center', minHeight: 500 }}
        >
          <Spin />{' '}
        </div>
      ) : !allLicenses?.agencyLicenses.length ? (
        <div
          style={{
            display: 'grid',
            placeContent: 'center',
            minHeight: '400px',
          }}
        >
          <EmptyState
            image="empty data"
            heading=""
            width="100%"
            content="No Records Found"
          />
        </div>
      ) : (
        <Spin spinning={searchLoading}>
          <div className="my-5">
            {selectedTab === AGENCY_LICENSE_VIEW.LIST ? (
              <>
                <AllLicensesAgencyList
                  {...{
                    isFilterVisible,
                    collapseKeys,
                    allLicenses,
                    onCollapseChange,
                    refetch: () => {
                      fetchAllLicenses(queryText || '');
                    },
                    setErrors,
                    setApplyLicenseErrorModalVisibility,
                  }}
                />
              </>
            ) : (
              <AllLicensesAgencyMap allLicenses={allLicenses} />
            )}
          </div>
        </Spin>
      )}
      <Modal
        title={null}
        closable={!refreshModal.loading}
        maskClosable={false}
        centered
        onCancel={() => {
          setRefreshModal((prev) => ({
            ...prev,
            isOpen: false,
            showAgain: false,
            loading: false,
          }));
        }}
        open={refreshModal.isOpen}
        footer={[
          <Button
            id="all-licenses-cancel-button"
            key="cancel-button"
            disabled={refreshModal.loading}
            onClick={() => {
              setRefreshModal((prev) => ({
                ...prev,
                isOpen: false,
                showAgain: false,
                loading: false,
              }));
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            id="all-licenses-refresh-licenses-button"
            onClick={refreshLicenses}
            loading={refreshModal.loading}
            disabled={refreshModal.loading}
          >
            Refresh Licenses
          </Button>,
        ]}
      >
        <p style={{ fontSize: 20, fontWeight: 'bold' }} id="refresh-licenses">
          Do you want to refresh licenses ?
        </p>
        <p style={{ color: '#99ACB5' }}>
          Licenses that have been refreshed once cannot be refreshed again
          within 24 hours.
        </p>
        {refreshModal.showAgain ? (
          <div
            style={{
              display: 'flex',
              gap: 8,
              marginTop: 12,
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={refreshModal.checkBox}
              onChange={(e) => {
                setRefreshModal((prev) => ({
                  ...prev,
                  checkBox: true,
                }));
              }}
              className="cursor-pointer"
              id={'refresh-licenses-do-not-show-again'}
            />
            Do not show this again
          </div>
        ) : (
          false
        )}
      </Modal>
      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        controlledFilters={controlledFilters}
        fetchFilters={() => {
          return new Promise((resolve) =>
            resolve([] as FilterFieldsMetaData[])
          );
        }}
        clearFilters={() => {
          clearFilters();
        }}
        pageName={'All Licenses Agency List'}
        filterPage={PageConstants.ALL_AGENCY_LICENSES}
      />
      <ApplyLicenseErrorModal
        applyLicenseErrorModalVisibility={applyLicenseErrorModalVisibility}
        setApplyLicenseErrorModalVisibility={
          setApplyLicenseErrorModalVisibility
        }
        errors={errors}
        fetchLicense={() => fetchAllLicenses(queryText || '')}
      />
    </div>
  );
}

export default withRoles(AllLicensesAgencyTab, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
