import { ListBuilderLevel, ListBuilderType } from './listBuilderUtils';

import { Name } from '../../../../types/data/name.type';
import { Table } from 'antd';
import { getNameString } from '../../../../utils/name.utils';

export interface ListType {
  type: 'agency' | 'producer';
  _id: string;
  level: number;
  name: string | Name;
  npn: number;
}

export interface SortType {
  field: keyof ListType;
  order: string;
}

interface ListBuilderProps {
  listDetails: ListType[];
  isLoading: boolean;
  setSortType: React.Dispatch<React.SetStateAction<SortType | null>>;
}

const ListBuilder: React.FC<ListBuilderProps> = ({
  listDetails,
  isLoading,
  setSortType,
}) => {
  const Columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a: ListType, b: ListType) => a?.type?.localeCompare(b?.type),
      width: '300px',
      render: (text: string) => {
        return (
          <div>
            <ListBuilderType
              styles={{ marginLeft: '12px', textTransform: 'capitalize' }}
              isProducer={text === 'producer'}
            >
              {text}
            </ListBuilderType>
          </div>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: ListType, b: ListType) =>
        getNameString(a?.name)?.localeCompare(getNameString(b?.name)),
      width: '300px',
      render: (text: string | Name) => {
        return (
          <div>{typeof text === 'string' ? text : getNameString(text)}</div>
        );
      },
    },
    {
      dataIndex: 'level',
      key: 'level',
      width: '600px',
      sorter: (a: ListType, b: ListType) => a?.level - b?.level,
      render: (text: string) => {
        return (
          <div>
            <ListBuilderLevel styles={{ float: 'right', marginRight: 12 }}>
              {`Level ${text}`}
            </ListBuilderLevel>
          </div>
        );
      },
    },
  ];

  return (
    <div className="table-layout-v2 relative width-100">
      <Table
        columns={Columns}
        dataSource={listDetails}
        scroll={{
          y: 'calc(100vh - 210px)',
        }}
        loading={isLoading}
        pagination={false}
        onChange={(_, __, sort: any, ___) => {
          if (!sort?.order) {
            setSortType(null);
          } else {
            setSortType({ field: sort?.field, order: sort?.order });
          }
        }}
      />
    </div>
  );
};

export default ListBuilder;
