interface LevelProps {
  children: React.ReactNode;
  styles?: object;
}

export const ListBuilderLevel: React.FC<LevelProps> = ({
  children,
  styles,
}) => {
  return (
    <div
      style={{
        padding: '1px 8px',
        background: '#FCEFCF',
        border: '1px solid #F8D79A',
        borderRadius: '26px',
        width: 'fit-content',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#001F45',
        ...(styles || {}),
      }}
      className="figtree"
    >
      {' '}
      {children}
    </div>
  );
};

interface TypeProps extends LevelProps {
  isProducer?: boolean;
}

export const ListBuilderType: React.FC<TypeProps> = ({
  children,
  styles,
  isProducer,
}) => {
  return (
    <div
      style={{
        padding: '1px 6px',
        background: isProducer
          ? '#EDF9FF'
          : 'var(--Secondary-Secondary100, #D3E7FF)',
        border: `1px solid ${isProducer ? '#001F45' : '#00A6FB'}`,
        borderRadius: '26px',
        width: 'fit-content',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#001F45',
        ...(styles || {}),
      }}
      className="figtree"
    >
      {' '}
      {children}
    </div>
  );
};
