import {
  AccountLink,
  StripeAccountDetailsDto,
} from '../types/data/stripe/stripeAccount.type';
import {
  AccountWithProducerDocument,
  AgentDocument,
  AgentInfo,
} from '../types/response/data/agents/agentDocument.type';
import {
  LicenseLookupState,
  SSNLookupState,
} from '../types/request/agents/bulkLookup.type';
import {
  OnboardProducerType,
  ProducerLicensingInfo,
  UpdatedPaymentConfig,
} from '../pages/agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import axios, { CancelTokenSource } from 'axios';

import { ActivityTimelineFilters } from '../types/request/agents/activityTimelineFilters.type';
import { AgentActivityTimelineEvent } from '../types/response/data/agents/agentActivityTimelineEvent.type';
import { AgentAssignmentsFilters } from '../types/request/agents/assignmentFilters.type';
import { AgentOnboardOneDetailsResponse } from '../types/response/data/agents/agentOnboardOneDetailsResponse.type';
import { AgentsRtsTableType } from '../pages/agency/agencyRts/agencyRtsTypes';
import { Appointments } from '../pages/agents/RTS/agentSpecificRTS';
import { ArraySuccessResponse } from '../types/response/arraySuccessResponse.type';
import { AssignedState } from '../types/data/stateGroup.types';
import { AssignmentAgent } from '../types/data/allAgents.type';
import { ErrorResponse } from '../types/response/errorResponse.type';
import { IndividualOnboardDto } from '../types/request/agents/individualOnboardType.type';
import { LicenseDto } from '../types/data/rts.type';
import { Name } from '../types/data/name.type';
import { NiprLookup } from '../types/data/niprLookup';
import { ObjectSuccessResponse } from '../types/response/objectSuccessResponse.type';
import { OnboardExistingAgentDto } from '../types/request/agents/onboardExistingAgent.type';
import { PaginatedAgentsSuccessResponse } from '../types/response/PaginatedAgentsSuccessResponse.type';
import { PaginatedSuccessResponse } from '../types/response/paginatedSuccessResponse.type';
import { StripeBalance } from '../types/data/stripe/stripeBalance.type';
import { UrlConstants } from '../constants/url.constants';
import { adminStore } from '../stores/admin.store';
import { apiStore } from '../stores/api.store';
import { cancelPreviousRequest } from '../utils/api.utils';
import { filterRequestBody } from '../utils/common.utils';
import { message } from 'antd';

export interface AgentsFilters {
  name?: string;
  email?: string;
  npn?: string;
  agencyId?: string;
  homeState?: string;
  isInvited?: boolean;
  isVerified?: boolean;
  isRegistered?: boolean;
}
export class AgentService {
  static host = UrlConstants.backend;
  static modulePath = '/agents';

  /**
   * Fetch my agency's agent assignments
   * /agents/my-agency/assignments
   * @param pageNumber
   * @param pageSize
   * @param accessToken
   * @param searchString
   * @param assignmentFilters
   * @returns
   */
  static async getMyAgentsAssignments(
    page: number,
    size: number,
    accessToken: string,
    searchString: string,
    searchType: string,
    assignmentFilters?: AgentAssignmentsFilters,
    cancelTokenSource?: CancelTokenSource,
    reqBody?: object
  ) {
    const path = `/agency/assignments`;
    const url_ = new URL(`${this.host}${path}`);
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    // Process requestBody using filterRequestBody function
    if (reqBody) filterRequestBody(reqBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(accessToken, cancelTokenSource)
          .get(`${url_}`);
        resolve(response.data as PaginatedSuccessResponse<AssignmentAgent>);
      } catch (error: any) {
        console.error(
          'AgentService :: getMyAgentsAssignments :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getAgentsforStateGroup(
    stateGroupId: string,
    token: string,
    isAssigned?: boolean,
    existingAgentIds?: string[]
  ): Promise<PaginatedSuccessResponse<AgentInfo>> {
    const path = '/account/producer/territory/{id}';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore.getApiClient(token).get(
          `${this.host}${path.replace('{id}', stateGroupId)}?${
            isAssigned !== undefined
              ? `isAssigned=${isAssigned}`
              : `isAssigned=${true}`
          }`
          // {
          //   excludeAgentIds: existingAgentIds ? existingAgentIds : [],
          // }
        );
        resolve(response.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  // get license configuration for the agent
  static async getLicenseConfigurationByProducerId(
    id: string,
    token: string
  ): Promise<PaginatedSuccessResponse<any>> {
    const path = `/account/producer/${id}/license-configurations`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}`);
        resolve(response.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Create a new agent - Onboards an agent
   * /my-agency
   * @param agent
   * @param bearerToken
   * @returns
   */
  static async createAgentForAgency(agent: Object, bearerToken: string) {
    const path = '/agents/my-agency';
    return new Promise(async (resolve, reject) => {
      try {
        const createdAgent = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`, agent, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(createdAgent);
      } catch (error: any) {
        console.error(
          'AgentService :: createAgent :: Error :: ',
          error?.message
        );
        reject(error?.response?.data as ErrorResponse);
      }
    });
  }

  /**
   * Retrieves line only agents whose payment configurations are not completed
   * /agents/my-agency
   * @param agencyId
   * @param pageSize
   * @param pageNumber
   * @param bearerToken
   * @returns
   */
  static async getUnconfiguredPaymentAgents(
    pageSize: number | null,
    pageNumber: number | null,
    bearerToken: string
  ): Promise<PaginatedSuccessResponse<AgentDocument>> {
    const path = '/agents/my-agency';
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(
          apiStore.getTokenForGetUnconfiguredPaymentAgents()
        );
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetUnconfiguredPaymentAgents(cancelTokenSource);
        const unconfiguredPaymentAgents = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .get(
            `${this.host}${path}?${
              pageSize !== null ? `&size=${pageSize}` : ''
            }${
              pageNumber !== null ? `&page=${pageNumber}` : ''
            }&isPaymentConfigNull=true`
          );
        apiStore.setTokenForGetUnconfiguredPaymentAgents(undefined);
        resolve(unconfiguredPaymentAgents.data);
      } catch (error: any) {
        console.error('AgentService :: search :: Error :: ', error?.message);
        apiStore.setTokenForGetUnconfiguredPaymentAgents(undefined);
        reject(error);
      }
    });
  }

  /**
   * Retrieves agents information for an agency ID
   * @param id
   * @param size
   * @param page
   * @param search
   * @param accesstoken
   * @returns
   */
  static async getAgentsForHierarchyViewTable(
    size: number,
    page: number,
    search: string,
    accesstoken: string
  ) {
    return new Promise(async (resolve, reject) => {
      const apiPath = '/agents';
      try {
        const response = await apiStore
          .getApiClient(accesstoken)
          .post(
            `${
              this.host
            }${apiPath}?&size=${size}&page=${page}&searchString=${encodeURIComponent(
              search
            )}`,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        const agentData =
          response.data.data &&
          response.data.data.map((data: any) => {
            return {
              id: data.id,
              name: data.name.firstName + ' ' + data.name.lastName,
              npn: data.npn,
              type: 'LOA',
              addressLastUpdatedAt: data.addressLastUpdatedAt,
            };
          });
        resolve({
          count: response.data.totalCount,
          agentData: agentData,
          allAgentDetails: response.data.data,
        });
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Retrieves license information for an agency ID
   * @param id
   * @param accesstoken
   * @returns
   */
  static async getLicenseStatusByAgent(id: string, accesstoken: string) {
    return new Promise(async (resolve, reject) => {
      const apiPath = `/license/producer-overview-map/${id}`;
      try {
        const response = await apiStore
          .getApiClient(accesstoken)
          .get(`${this.host}${apiPath}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(response.data.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  static async getLicenseStatus(accesstoken: string) {
    return new Promise(async (resolve, reject) => {
      const apiPath = `/license/producer-overview-map`;
      try {
        const response = await apiStore
          .getApiClient(accesstoken)
          .get(`${this.host}${apiPath}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(response.data.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Retrieves agent bio information given agentId
   * /agents/{id}
   * @param id
   * @param bearerToken
   * @returns
   */
  static async getAgentDetails(id: string, bearerToken: string) {
    const path = '/account/producer/{id}/minimal';
    return new Promise(async (resolve, reject) => {
      try {
        const agent = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path.replace('{id}', id)}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        const addressDict: any = {};
        agent?.data?.data?.stateAddresses?.forEach((stateAddress: any) => {
          if (stateAddress.state === agent?.data?.data?.homeState) {
            stateAddress?.addresses.forEach((stateAddress: any) => {
              addressDict[stateAddress.addressTypeCode] = stateAddress;
            });
          }
        });
        agent.data.data.address = addressDict;
        resolve(agent.data);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Retrieves information of the current logged in agent
   * /agents/me
   * @param bearerToken
   * @returns
   */
  static async getCurrentAgent(bearerToken: string) {
    const path = '/account/producer/minimal';
    return new Promise(async (resolve, reject) => {
      try {
        const agent = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        resolve(agent.data);
      } catch (error: any) {
        console.error('AgentService getCurrentAgent Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Retrieves list of first level downline agent for given agencyID
   * '/agents/{agencyId}/first-level-downline-agents
   * @param agencyId
   * @param pageSize
   * @param pageNumber
   * @param searchString
   * @param accessToken
   * @returns
   */
  static async getFirstLevelDownlineAgents(
    agencyId: string,
    pageSize: number | null,
    pageNumber: number | null,
    searchString: string | null,
    accessToken: string,
    requestBody: Object = {}
  ): Promise<PaginatedSuccessResponse<AgentDocument>> {
    const path = '/agents/{agencyId}/first-level-downline-agents';
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(
          apiStore.getTokenForGetFirstLevelDownlineAgents()
        );
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetFirstLevelDownlineAgents(cancelTokenSource);
        const agents: any = await apiStore
          .getApiClient(accessToken, cancelTokenSource)
          .post(
            `${this.host}${path}${
              pageSize !== null ? `?size=${pageSize}` : ''
            }${pageNumber !== null ? `&page=${pageNumber}` : ''}${
              searchString
                ? `&searchString=${encodeURIComponent(searchString)}`
                : ''
            }`.replace('{agencyId}', agencyId),
            requestBody
          );
        apiStore.setTokenForGetFirstLevelDownlineAgents(undefined);
        resolve(agents.data);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        apiStore.setTokenForGetFirstLevelDownlineAgents(undefined);
        reject(error);
      }
    });
  }

  /**
   * Receives list of line only agents for given agencyID
   * /agents
   * @param filters
   * @param pageSize
   * @param pageNumber
   * @param searchString
   * @param accessToken
   * @returns
   */
  static async getMyAllAgents(
    pageSize: number | null,
    pageNumber: number | null,
    accessToken: string,
    searchString?: string
  ): Promise<PaginatedSuccessResponse<AgentDocument>> {
    const path = '/agents/my-agency';
    return new Promise(async (resolve, reject) => {
      try {
        const agents = await apiStore
          .getApiClient(accessToken)
          .get(
            `${this.host}${path}${
              pageSize !== null ? `?size=${pageSize}` : ''
            }${pageNumber !== null ? `&page=${pageNumber}` : ''}${
              searchString
                ? `&searchString=${encodeURIComponent(searchString)}`
                : ''
            }`,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );
        resolve(agents.data as PaginatedSuccessResponse<AgentDocument>);
      } catch (error: any) {
        apiStore.setTokenForGetAllAgents(undefined);
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }
  /**
   * Receives list of line only agents for given agencyID
   * /agents
   * @param filters
   * @param pageSize
   * @param pageNumber
   * @param searchString
   * @param accessToken
   * @returns
   */
  static async getAllProducersForAgency(
    accessToken: string,
    pageSize?: number | null,
    pageNumber?: number | null,
    searchString?: string,
    payload?: object,
    searchType?: string
  ): Promise<PaginatedSuccessResponse<AccountWithProducerDocument>> {
    let url_ = new URL(`${this.host}/account/all/producer`);
    if (pageSize) url_.searchParams.append('size', pageSize.toString());
    if (pageNumber) url_.searchParams.append('page', pageNumber.toString());
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    // Process requestBody using filterRequestBody function
    if (payload) filterRequestBody(payload, url_);
    // if (filters) {
    //   Object.keys(filters).forEach((key) => {
    //     url_.searchParams.append(key, (filters as any)[key]);
    //   });
    // }
    return new Promise(async (resolve, reject) => {
      try {
        const agents = await apiStore.getApiClient(accessToken).get(`${url_}`, {
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
          },
        });
        resolve(
          agents.data as PaginatedSuccessResponse<AccountWithProducerDocument>
        );
      } catch (error: any) {
        apiStore.setTokenForGetAllAgents(undefined);
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Receives list of line only agents for given agencyID
   * /agents
   * @param filters
   * @param pageSize
   * @param pageNumber
   * @param searchString
   * @param accessToken
   * @returns
   */
  static async getAllAgents(
    accessToken: string,
    filters: AgentsFilters | null,
    pageSize: number | null,
    pageNumber: number | null,
    agencyId?: string,
    searchString?: string | null,
    paymentConfig?: boolean,
    reqBody?: object
  ): Promise<PaginatedAgentsSuccessResponse<any>> {
    const adminAgencyId = adminStore.agency?.id ?? '';
    let modulePath = '';
    if (adminAgencyId === agencyId) modulePath = `/account/all/producer`;
    else {
      modulePath = `/account/all/${agencyId}/producer`;
    }
    let url_ = new URL(`${this.host}${modulePath}`);
    if (pageSize) url_.searchParams.append('size', pageSize.toString());
    if (pageNumber) url_.searchParams.append('page', pageNumber.toString());
    if (paymentConfig)
      url_.searchParams.append('isPaymentConfigNull', paymentConfig.toString());
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    // if (filters) {
    //   for (const key in filters) {
    //     if (filters.hasOwnProperty(key)) {
    //       const filterKey = key as keyof AgentsFilters;
    //       const filterValue = filters[filterKey];
    //       if (
    //         typeof filterValue === 'string' ||
    //         typeof filterValue === 'boolean'
    //       ) {
    //         url_.searchParams.append(
    //           `${encodeURIComponent(filterKey)}=${encodeURIComponent(
    //             filterValue
    //           )}`
    //         );
    //       }
    //     }
    //   }
    // }
    // Process requestBody using filterRequestBody function
    filterRequestBody(reqBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(apiStore.getTokenForGetAllAgents());
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetAllAgents(cancelTokenSource);
        const agents = await apiStore
          .getApiClient(accessToken, cancelTokenSource)
          .get(`${url_}`, { data: reqBody });
        apiStore.setTokenForGetAllAgents(undefined);

        resolve(agents.data as PaginatedAgentsSuccessResponse<AgentDocument>);
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          apiStore.setTokenForGetAllAgents(undefined);
        }
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Receives list of line only agents for given agencyID
   * /agents
   * @param filters
   * @param pageSize
   * @param pageNumber
   * @param searchString
   * @param accessToken
   * @returns
   */
  static async getAllAgents2(
    filters: AgentsFilters | null,
    pageSize: number | null,
    pageNumber: number | null,
    searchString: string | null,
    accessToken: string
  ): Promise<PaginatedSuccessResponse<AgentDocument>> {
    const modulePath = '/agents';
    return new Promise(async (resolve, reject) => {
      try {
        const queryParams = [];
        if (filters) {
          for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
              const filterKey = key as keyof AgentsFilters;
              const filterValue = filters[filterKey];
              if (
                typeof filterValue === 'string' ||
                typeof filterValue === 'boolean'
              ) {
                queryParams.push(
                  `${encodeURIComponent(filterKey)}=${encodeURIComponent(
                    filterValue
                  )}`
                );
              }
            }
          }
        }
        const queryString =
          queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
        const url = `${this.host}${modulePath}${queryString}${
          pageSize !== null ? `&size=${pageSize}` : ''
        }${pageNumber !== null ? `&page=${pageNumber}` : ''}${
          searchString
            ? `&searchString=${encodeURIComponent(searchString)}`
            : ''
        }`;
        cancelPreviousRequest(apiStore.getTokenForGetAllAgents2());
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetAllAgents2(cancelTokenSource);
        const agents = await apiStore
          .getApiClient(accessToken, cancelTokenSource)
          .get(url);
        apiStore.setTokenForGetAllAgents2(undefined);
        resolve(agents.data as PaginatedSuccessResponse<AgentDocument>);
      } catch (error: any) {
        apiStore.setTokenForGetAllAgents2(undefined);
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Updates an agent's biographic information
   * /agents/{id}/agent-overview-info
   * @param agentId
   * @param updateAgent
   * @param bearerToken
   * @returns
   */
  static async updateAgentBioInfo(
    agentId: string,
    updateAgent: Object,
    bearerToken: string
  ) {
    const path = '/agents/{id}/agent-overview-info';

    return new Promise(async (resolve, reject) => {
      try {
        const updatedAgent = await apiStore
          .getApiClient(bearerToken)
          .patch(`${this.host}${path}`.replace('{id}', agentId), updateAgent, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });

        resolve(updatedAgent);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Get assignments for a given agent
   * /agents/{agentId}/assigned-states
   * @param agentId
   * @param bearerToken
   * @returns
   */
  static async getAssignment(
    agentId: string,
    bearerToken: string,
    cancelTokenSource?: CancelTokenSource,
    requestBody?: object
  ): Promise<ArraySuccessResponse<AssignedState>> {
    const path = '/agents/{agentId}/assigned-states';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .post(
            `${this.host}${path.replace('{agentId}', agentId)}`,
            requestBody,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          );
        resolve(
          response.data as unknown as ArraySuccessResponse<AssignedState>
        );
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Get assignments for a given agent
   * /agents/assigned-states
   * @param agentId
   * @param bearerToken
   * @returns
   */
  static async getAssignmentForMultipleOnboarding(
    reportId: string,
    bearerToken: string,
    agentIds: string[],
    cancelTokenSource?: CancelTokenSource,
    requestBody?: object
  ): Promise<ArraySuccessResponse<AssignedState>> {
    const path = '/agents/assigned-states';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .post(
            `${this.host}${path}`,
            { reportId: reportId, ...requestBody, agentIds: agentIds },
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          );
        resolve(
          response.data as unknown as ArraySuccessResponse<AssignedState>
        );
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  // RTS Start
  /**
   * Get appointments for a given agent
   * @param agentId
   * @param bearerToken
   * @returns
   */
  static async getAppoinments(
    agentId: string,
    bearerToken: string
  ): Promise<ArraySuccessResponse<Appointments>> {
    const path =
      '/ready-to-sell/{agentId}/appointment/all?skip-pagination=true';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path.replace('{agentId}', agentId)}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data as unknown as ArraySuccessResponse<Appointments>);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Get appointments for a given agent
   * @param agentId
   * @param bearerToken
   * @returns
   */
  static async getAppoinmentsForAgentPortal(
    bearerToken: string
  ): Promise<ArraySuccessResponse<Appointments>> {
    const path = '/ready-to-sell/appointment/all';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data as unknown as ArraySuccessResponse<Appointments>);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Get Licenses for a given agent
   * @param agentId
   * @param bearerToken
   * @returns
   */
  static async getLicenses(
    agentId: string,
    bearerToken: string,
    page: number,
    size: number,
    searchString?: string
  ): Promise<ArraySuccessResponse<LicenseDto>> {
    const path = `/ready-to-sell/${agentId}/license/all`;
    const url_ = new URL(`${this.host}${path}`);
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data as unknown as ArraySuccessResponse<LicenseDto>);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Get Licenses for a given agent
   * @param agentId
   * @param bearerToken
   * @returns
   */
  static async getLicensesForAgentPortal(
    bearerToken: string
  ): Promise<ArraySuccessResponse<Appointments>> {
    const path = '/ready-to-sell/license/all';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data as unknown as ArraySuccessResponse<Appointments>);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  // RTS End

  /**
   * Updates state addresses for agent during onboarding (with data fetched from NIPR)
   * /agents/{id}/stateAddress
   * @param agentId
   * @param addresses
   * @param bearerToken
   * @returns
   */
  static async pushAddresses(
    agentId: string,
    addresses: object[],
    bearerToken: string
  ) {
    const path = '/agents/{id}/stateAddress';
    return new Promise(async (resolve, reject) => {
      try {
        const results = [];
        for (const addressObject of addresses) {
          const agent = await apiStore
            .getApiClient(bearerToken)
            .post(
              `${this.host}${path.replace('{id}', agentId)}`,
              addressObject,
              {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              }
            );
          results.push(agent);
        }
        resolve(results);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * updates all state addresses, given a list of addresses during onboard
   * /agents/{id}/allStateAddresses
   * @param agentId
   * @param addresses
   * @param bearerToken
   * @returns
   */
  static async updateAllStateAddress(
    agentId: string,
    addresses: object[],
    bearerToken: string
  ) {
    const path = '/agents/{id}/allStateAddresses';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore.getApiClient(bearerToken).patch(
          `${this.host}${path.replace('{id}', agentId)}`,
          { addresses: addresses },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );
        resolve(response);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * created an employment history for an agent
   * /agents/{id}/employment-history/many
   * @param empHistory
   * @param bearerToken
   * @returns
   */
  static async createEmploymentHistory(
    empHistory: object[],
    bearerToken: string,
    agentId?: string
  ) {
    const path = agentId
      ? `/account/producer/${agentId}/employment-history`
      : `/account/producer/employment-history`;
    return new Promise(async (resolve, reject) => {
      try {
        const agent = await apiStore.getApiClient(bearerToken).post(
          `${this.host}${path}`,
          { employmentHistory: empHistory },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );

        resolve(agent.data);
      } catch (error: any) {
        resolve(error);
        console.error('AgentService Error :: ', error?.message);
        message.error(
          error?.response?.data?.message ||
            'Failed to update employment history.please try again'
        );
      }
    });
  }

  /**
   * Retrieves employment history for an agent
   * /agents/{id}/employment-history?size=50&page=1'
   * @param bearerToken
   * @returns
   */
  static async getEmploymentHistory(bearerToken: string, agentId?: string) {
    const path = agentId
      ? `/account/producer/${agentId}/employment-history`
      : `/account/producer/employment-history`;
    return new Promise(async (resolve, reject) => {
      try {
        const agent = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });

        resolve(agent.data);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Retrieves background questions for an agent
   * /agents/{id}/background-question
   * @param bearerToken
   * @returns
   */
  // TODO This should not be requiring agentID
  static async getOnboardedAgentBackgroundQuestions(
    bearerToken: string,
    agentId?: string,
    isMyAgency?: boolean
  ) {
    const path = isMyAgency
      ? `/agency/background-questions`
      : agentId
      ? `/account/producer/${agentId}/bg-question`
      : `/account/producer/bg-question`;

    return new Promise(async (resolve, reject) => {
      try {
        const backgroundQuestions = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(
          backgroundQuestions.data?.data?.map((d: any) => ({
            ...d,
            questionId: d.questionKey,
          }))
        );
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Updates Assignments for given list of agents
   * /agents/bulk/assign-states
   * @param agentIds
   * @param assignStates
   * @param bearerToken
   * @returns
   */
  static async postAssignedStates(
    agentIds: string[],
    assignStates: AssignedState[],
    bearerToken: string,
    reportId?: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const assignedAgent = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}/agents/bulk/assign-states`, {
            ids: agentIds,
            assignedStates: assignStates.map((itr) => {
              return {
                ...(itr.stateGroupId && { stateGroupId: itr.stateGroupId }),
                stateCode: itr.stateCode,
                licenseConfigs: itr.licenseConfigs,
              };
            }),
            reportId: reportId && reportId,
          });
        resolve(assignedAgent);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error.response.data as ErrorResponse);
      }
    });
  }

  /**
   * Offboards given list of agents
   * /agents/bul
   * @param ids
   * @param bearerToken
   * @returns
   */
  static async offboardAgents(ids: string[], bearerToken: string) {
    const path = '/agency/offboard-producerId';
    return new Promise(async (resolve, reject) => {
      try {
        const offboardedAgent = await apiStore
          .getApiClient(bearerToken)
          .delete(`${this.host}${path}`, {
            data: { producerIds: ids },
          });
        resolve(offboardedAgent);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error?.response);
      }
    });
  }

  /**
   * Offboards given list of agents
   * /agents/bul
   * @param npns
   * @param bearerToken
   * @returns
   */
  static async offboardAgentsByNpn(npns: string[], bearerToken: string) {
    const path = '/agency/bulk/offboard';
    return new Promise(async (resolve, reject) => {
      try {
        const offboardedAgent = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`, {
            data: { npns },
          });
        resolve(offboardedAgent);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error?.response);
      }
    });
  }

  /**
   * Adds background questions answered by an agent during signup
   * /agents/{id}/background-question/bulk-save
   * @param bearerToken
   * @param formData
   * @param agentId
   * @returns
   */
  static async postBackGroundQuestions(
    bearerToken: string,
    formData: any,
    agentId?: string,
    myAgency?: boolean
  ) {
    const path = myAgency
      ? `/agency/background-questions`
      : agentId
      ? `/account/producer/${agentId}/bg-question`
      : `/account/producer/bg-question`;
    return new Promise(async (resolve, reject) => {
      try {
        const backgroundQuestions = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`, formData, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          });
        if (backgroundQuestions.status === 201)
          resolve(backgroundQuestions.data);
        else throw backgroundQuestions;
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Updates Initial Payment Payee for the given agent(s)
   * /agents/bulk/paymentConfig
   * @param agentIds
   * @param payee
   * @param bearerToken
   * @returns
   */
  static async updateInitialPayee(
    agentIds: string[],
    payee: string,
    bearerToken: string,
    paymentType?: string
  ) {
    const path = '/agents/bulk/paymentConfig';
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(apiStore.getTokenForUpdateInitialPayee());
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForUpdateInitialPayee(cancelTokenSource);
        const agentConfig = await apiStore
          .getApiClient(bearerToken, cancelTokenSource)
          .patch(
            `${this.host}${path}`,
            {
              ids: agentIds,
              paymentConfig: {
                initialPaymentPayee: payee,
                paymentType: paymentType,
              },
            },
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          );
        apiStore.setTokenForUpdateInitialPayee(undefined);
        resolve(agentConfig.data);
      } catch (error: any) {
        console.error('AgentService Error:: ', error?.message);
        apiStore.setTokenForUpdateInitialPayee(undefined);
        reject(error);
      }
    });
  }

  /**
   * Updates Renewal Payment Payee for the given agent(s)
   * /agents/bulk/paymentConfig
   * @param agentIds
   * @param payee
   * @param bearerToken
   * @returns
   */
  static async updateRenewalPayee(
    agentIds: string[],
    payee: string,
    bearerToken: string
  ) {
    const path = '/agents/bulk/paymentConfig';
    return new Promise(async (resolve, reject) => {
      try {
        const agentConfig = await apiStore.getApiClient(bearerToken).patch(
          `${this.host}${path}`,
          {
            ids: agentIds,
            paymentConfig: {
              renewalPaymentPayee: payee,
            },
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );

        resolve(agentConfig.data);
      } catch (error: any) {
        console.error('AgentService Error:: ', error?.message);
        throw error;
      }
    });
  }

  /**
   * Updates Payment Type for the given agent(s)
   * /agents/bulk/paymentConfig
   * @param agentIds
   * @param paymentType
   * @param capAmount
   * @param bearerToken
   * @returns
   */
  static async updatePaymentCap(
    agentIds: any,
    paymentType: string,
    capAmount: number | null,
    bearerToken: string
  ) {
    const path = '/agents/bulk/paymentConfig';
    return new Promise(async (resolve, reject) => {
      try {
        const agentConfig = await apiStore.getApiClient(bearerToken).patch(
          `${this.host}${path}`,
          {
            ids: agentIds,
            paymentConfig: {
              paymentType: paymentType,
              amountCap: capAmount,
            },
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );

        resolve(agentConfig.data);
      } catch (error: any) {
        console.error('AgentService Error:: ', error?.message);
        throw error;
      }
    });
  }

  /**
   * Updates Payment Type for the given agent(s)
   * /agents/bulk/paymentConfig
   * @param agentIds
   * @param paymentType
   * @param capAmount
   * @param bearerToken
   * @returns
   */
  static async updatePaymentConfig(
    agentIds: any,
    paymentConfig: UpdatedPaymentConfig,
    bearerToken: string,
    autoRenewEnabled: boolean
  ) {
    const path = '/account/producer/payment-configs';
    return new Promise(async (resolve, reject) => {
      try {
        const agentConfig = await apiStore.getApiClient(bearerToken).post(
          `${this.host}${path}`,
          {
            producerIds: agentIds,
            paymentConfig: {
              licenseInitialPayee: paymentConfig.licenseInitialPayee,
              licenseRenewalPayee: paymentConfig.licenseRenewalPayee,
              paymentType: paymentConfig.paymentType, //todo
              isAutoRenewalActive: paymentConfig.isAutoRenewalActive,
              // amountCap: paymentConfig.amountCap || -1,
            },
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );

        resolve(agentConfig.data);
      } catch (error: any) {
        console.error('AgentService Error:: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Updates Auto Apply for the current logged in agent
   * /agents/bulk/paymentConfig
   * @param renew
   * @param bearerToken
   * @returns
   */
  static async updateMyAutoRenew(renew: boolean, bearerToken: string) {
    const path = '/agents/me/paymentConfig';
    return new Promise(async (resolve, reject) => {
      try {
        const agentConfig = await apiStore.getApiClient(bearerToken).patch(
          `${this.host}${path}`,
          {
            autoRenewEnabled: renew,
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );

        resolve(agentConfig.data);
      } catch (error: any) {
        console.error('AgentService Error:: ', error?.message);
        reject(error);
      }
    });
  }

  static async updatePaymentConfigAutoRenew(
    requestBody: Object,
    bearerToken: string
  ) {
    const path = '/account/producer/payment-config';
    return new Promise(async (resolve, reject) => {
      try {
        const agentConfig = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`, requestBody, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(agentConfig.data);
      } catch (error: any) {
        console.error('AgentService Error:: ', error?.message);
        reject(error);
      }
    });
  }
  static async updateAutoRenew(
    agentIds: string[],
    renew: boolean,
    bearerToken: string
  ) {
    const path = '/agents/bulk/paymentConfig';
    return new Promise(async (resolve, reject) => {
      try {
        const agentConfig = await apiStore.getApiClient(bearerToken).patch(
          `${this.host}${path}`,
          {
            ids: agentIds,
            autoRenewEnabled: renew,
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );

        resolve(agentConfig.data);
      } catch (error: any) {
        console.error('AgentService Error:: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Search agents using NPN
   * /agents/getBulkByNPN
   * @param pageSize
   * @param pageNumber
   * @param npnArray
   * @param bearerToken
   * @param filters
   * @returns
   */
  static async findManyByNPN(
    pageSize: number | null,
    pageNumber: number | null,
    npnArray: string[],
    bearerToken: any
  ) {
    const path = '/agents/getBulkByNPN';

    const url = `${this.host}${path}`;
    return new Promise(async (resolve, reject) => {
      try {
        const agents = await apiStore
          .getApiClient(bearerToken)
          .post(url, JSON.stringify(npnArray), {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(agents);
      } catch (error: any) {
        console.error('AgentService :: agents :: Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Search agents using SSN & Last Name
   * /agents/getBulkBySSN
   * @param agencyId
   * @param ssnLookupObject
   * @param bearerToken
   * @returns
   */
  static async findManyBySSNLastName(
    ssnLookupObject: SSNLookupState[],
    bearerToken: string
  ) {
    const path = '/agents/getBulkBySSN';

    const url = `${this.host}${path}`;
    return new Promise(async (resolve, reject) => {
      try {
        const agents = await apiStore
          .getApiClient(bearerToken)
          .post(url, ssnLookupObject, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(agents);
      } catch (error: any) {
        console.error(
          'AgentService :: findManyBySSNLastName :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Search one agent using NPN
   * /agents/{npn}/getOneByNPN
   * @param npn
   * @param bearerToken
   * @returns
   */
  static async findOneByNPN(npn: string, bearerToken: any) {
    const path = '/agents/{npn}/getOneByNPN';
    const url = `${this.host}${path.replace('{npn}', npn)}`;
    return new Promise(async (resolve, reject) => {
      try {
        const agents = await apiStore.getApiClient(bearerToken).get(url, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
        resolve(agents);
      } catch (error: any) {
        console.error('AgentService :: agents :: Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Search agents using License and State
   * /agents/getBulkByLicense
   * @param filters
   * @param licenseLookupObject
   * @param bearerToken
   * @returns
   */
  static async findManyByLicenseState(
    licenseLookupObject: LicenseLookupState[],
    bearerToken: any
  ) {
    const path = '/agents/getBulkByLicense';

    const url = `${this.host}${path}`;

    return new Promise(async (resolve, reject) => {
      try {
        const agents = await apiStore
          .getApiClient(bearerToken)
          .post(url, licenseLookupObject, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(agents);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Updates background questions
   * /agents/{id}/background-question/{questionId}
   * @param bearerToken
   * @param formData
   * @param agentId
   * @param questionId
   * @returns
   */
  static async ediBackGroundQuestions(
    bearerToken: string,
    formData: any,
    questionId: string,
    agentId: string
  ) {
    const path = `/agents/${agentId || 'me'}/background-question/${questionId}`;
    return new Promise(async (resolve, reject) => {
      try {
        const backgroundQuestions = await apiStore
          .getApiClient(bearerToken)
          .patch(
            `${this.host}${path.replace('{questionId}', questionId)}`,
            formData,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
              },
            }
          );

        if (backgroundQuestions.status === 200)
          resolve(backgroundQuestions.data);
        else throw backgroundQuestions;
      } catch (error: any) {
        console.error('ediBackGroundQuestions Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Retrieves one agent information from NIPR
   * /agents
   * @param niprLookupObject
   * @param bearerToken
   * @returns
   */
  static async agentOnboardOneDetails(
    niprLookupObject: NiprLookup,
    bearerToken: string
  ): Promise<ObjectSuccessResponse<AgentOnboardOneDetailsResponse>> {
    return new Promise(async (resolve, reject) => {
      const path = '/agents';

      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .post(
            `${this.host}${path}/onboard-one-agent-details`,
            niprLookupObject,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        resolve(
          response.data as ObjectSuccessResponse<AgentOnboardOneDetailsResponse>
        );
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Onboard Individual Producer
   * /agents
   * @param producerDetails
   * @param bearerToken
   * @returns
   */
  static async onboardIndividualProducer(
    producerDetails: OnboardProducerType,
    bearerToken: string
  ): Promise<
    ObjectSuccessResponse<{
      producerId: string;
      message: string;
      watchListReason?: string;
      result?: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      const path = '/agency/onboard/producer';

      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`, producerDetails, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        resolve(
          response?.data as ObjectSuccessResponse<{
            producerId: string;
            message: string;
            watchListReason: string;
            result?: string;
          }>
        );
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Onboard Individual Producer
   * /agents
   * @param producerDetails
   * @param bearerToken
   * @returns
   */
  static async onboardIndividualProducerForDrlpAssignment(
    producerDetails: OnboardProducerType,
    stateCode: string,
    bearerToken: string
  ): Promise<
    ObjectSuccessResponse<{
      containsValidLicense: boolean;
      isNewAgent: boolean;
      watchListReason: null | string;
      producerId: string;
      message: string;
      couldNotBeOnboarded?: boolean;
      existingProducerDetails?: {
        name?: Name;
        npn?: string;
        ssn?: string;
        email?: string;
      };
    }>
  > {
    return new Promise(async (resolve, reject) => {
      const path = `/agency/onboard/producer/${stateCode}`;

      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`, producerDetails, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        resolve(
          response?.data as ObjectSuccessResponse<{
            containsValidLicense: boolean;
            isNewAgent: boolean;
            watchListReason: null | string;
            producerId: string;
            message: string;
          }>
        );
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Update Producer Details
   * /agents
   * @param producerDetails
   * @param bearerToken
   * @returns
   */
  static async updateProducerDetails(
    producerDetails: OnboardProducerType,
    bearerToken: string
  ): Promise<
    ObjectSuccessResponse<{
      producerId: string;
      message: string;
      watchListReason?: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      const path = '/agency/onboard/producer';

      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`, producerDetails, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        resolve(
          response.data as ObjectSuccessResponse<{
            producerId: string;
            message: string;
            watchListReason: string;
          }>
        );
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * get Producer details by id
   * /agents
   * @param producerId
   * @param bearerToken
   * @returns
   */
  static async getProducerDetailsById(
    producerId: string,
    bearerToken: string
  ): Promise<ProducerLicensingInfo> {
    return new Promise(async (resolve, reject) => {
      const path = `/account/producer/${producerId}/minimal`;

      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(response.data as ProducerLicensingInfo);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * get Assignment details by id
   * /agents
   * @param producerId
   * @param bearerToken
   * @returns
   */
  static async getAssignmentDetailsById(
    producerId: string,
    bearerToken: string
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const path = `/account/producer/${producerId}/assignments`;

      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(response.data as any);
      } catch (error: any) {
        console.error('AgentService Get Assignments Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Retrieves one agent information from NIPR for general agent firm signup
   * /agents
   * @param niprLookupObject
   * @param bearerToken
   * @returns
   */
  static async agentOnboardOneDetailsForSignup(
    niprLookupObject: NiprLookup,
    bearerToken: string
  ): Promise<ObjectSuccessResponse<AgentOnboardOneDetailsResponse>> {
    return new Promise(async (resolve, reject) => {
      const path = '/agents';

      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .post(
            `${this.host}${path}/onboard-one-agent-details-on-signup`,
            niprLookupObject,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        resolve(
          response.data as ObjectSuccessResponse<AgentOnboardOneDetailsResponse>
        );
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Adds existing offboarded agent to a new agency
   * /agents
   * @param agentId
   * @param updateAgentObject
   * @param bearerToken
   * @returns
   */
  static async onboardExistingAgent(
    agentId: string,
    onboardExistingAgentDto: OnboardExistingAgentDto,
    bearerToken: string
  ): Promise<ObjectSuccessResponse<any>> {
    return new Promise(async (resolve, reject) => {
      const path = '/agents';

      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .patch(
            `${this.host}${path}/${agentId}/onboard-existing-agent`,
            onboardExistingAgentDto,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );
        resolve(response.data as ObjectSuccessResponse<any>);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Fetches the balance for the current agent
   * @param bearerToken
   */
  static async getBalanceForCurrentAgent(
    token: string
  ): Promise<ObjectSuccessResponse<StripeBalance>> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.host}${this.modulePath}/me/balance`;
        const response = await apiStore.getApiClient(token).get(url);

        resolve(response.data as ObjectSuccessResponse<StripeBalance>);
      } catch (err) {
        reject(err);
      }
    });
  }

  static async getAgentActivityTimeline(
    agentId: string,
    pageSize: number,
    pageNumber: number,
    bearerToken: string,
    searchText: string,
    filters: ActivityTimelineFilters
  ): Promise<PaginatedSuccessResponse<AgentActivityTimelineEvent>> {
    try {
      const url = `${this.host}${
        this.modulePath
      }/${agentId}/activity?page=${pageNumber}&size=${pageSize}${
        searchText && `&searchString=${encodeURIComponent(searchText)}`
      }`;

      const response = await apiStore
        .getApiClient(bearerToken)
        .post(url, filters, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

      return response.data as PaginatedSuccessResponse<AgentActivityTimelineEvent>;
    } catch (error: any) {
      console.error('AgentService Error :: ', error?.message);
      throw error.response.data as ErrorResponse;
    }
  }

  static async applyLicensesByAgentId(
    agentIds: string[],
    type: string,
    bearerToken: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const licenseResponse = await apiStore
          .getApiClient(bearerToken)
          .post(
            `${this.host}${this.modulePath}/bulk/create-applications?type=${type}`,
            {
              data: agentIds,
            }
          );
        resolve(licenseResponse.data);
      } catch (error: any) {
        console.error(
          'AgentService :: applyLicenses :: Error :: ',
          error?.message
        );
        reject((error?.response?.data as ErrorResponse) || error);
      }
    });
  }

  /**
   * Onboards agent
   * /agents
   * @param agentId
   * @param individualOnboardDto
   * @param bearerToken
   * @returns
   */
  static async individualOnboard(
    individualOnboardDto: IndividualOnboardDto,
    bearerToken: string,
    isSignup = false
  ): Promise<ObjectSuccessResponse<any>> {
    return new Promise(async (resolve, reject) => {
      const path = '/agents';
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .post(
            `${this.host}${path}/individual-onboard${
              isSignup ? '-signup' : ''
            }`,
            individualOnboardDto,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );
        resolve(response.data as ObjectSuccessResponse<any>);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Fetches the stripe details for the current agent
   * @param bearerToken
   */
  static async getStripeDetailsForCurrentAgent(
    token: string
  ): Promise<ObjectSuccessResponse<StripeAccountDetailsDto>> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.host}${this.modulePath}/me/stripe`;
        const response = await apiStore.getApiClient(token).get(url);

        resolve(
          response.data as ObjectSuccessResponse<StripeAccountDetailsDto>
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Checks if the agency has been onboarded on stripe for the current agency
   * @param bearerToken
   */
  static async refreshIsOnboardedForCurrentAgent(
    token: string
  ): Promise<ObjectSuccessResponse<boolean>> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.host}${this.modulePath}/me/refresh-stripe-is-onboarded`;
        const response = await apiStore.getApiClient(token).get(url);

        resolve(response.data as ObjectSuccessResponse<boolean>);
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Fetches a new stripe account link for the current agent
   * @param bearerToken
   */
  static async refreshStripeOnboardingLinkForCurrentAgent(
    token: string
  ): Promise<ObjectSuccessResponse<AccountLink>> {
    try {
      const url = `${this.host}${this.modulePath}/me/refresh-stripe-onboarding-link`;
      const response = await apiStore.getApiClient(token).get(url);

      return response.data as ObjectSuccessResponse<AccountLink>;
    } catch (error) {
      console.error('error : ', error);
      throw error;
    }
  }

  /**
   * Creates a payment link for a topup
   */
  static async createTopupPaymentLinkForCurrentAgent(
    amount: number,
    token: string
  ): Promise<ObjectSuccessResponse<string>> {
    try {
      const url = `${this.host}${this.modulePath}/me/topup-payment-link?amount=${amount}`;
      const response = await apiStore.getApiClient(token).post(url);

      return response.data as ObjectSuccessResponse<string>;
    } catch (error: any) {
      console.error('error from service : ', error);
      throw error?.response?.data as ErrorResponse;
    }
  }

  /**
   * Fetches topup history for the current agent
   * @param bearerToken
   */
  static async getTopupHistoryForCurrentAgent(
    page: string | undefined,
    token: string
  ): Promise<ObjectSuccessResponse<any>> {
    try {
      const url = `${this.host}${this.modulePath}/me/topup-history${
        page ? `?page=${page}` : ' '
      }`;
      const response = await apiStore.getApiClient(token).get(url);

      return response.data as ObjectSuccessResponse<any>;
    } catch (error) {
      console.error('error : ', error);
      throw error;
    }
  }

  /**
   * Fetch Rts Records In Agency
   */
  static async fetchRtsRecordsInAgency(
    token: string,
    groupBy: 'stateCode' | 'companyName',
    stateCode?: string | null,
    companyName?: string,
    loas?: string[]
  ): Promise<ObjectSuccessResponse<AgentsRtsTableType[]>> {
    try {
      const url = `${this.host}/ready-to-sell`;
      const response = await apiStore.getApiClient(token).post(url, {
        ...(stateCode ? { stateCode } : {}),
        ...(loas?.length ? { loas } : {}),
        ...(companyName ? { companyName } : {}),
        groupBy,
      });
      return { data: response.data.data } as ObjectSuccessResponse<
        AgentsRtsTableType[]
      >;
    } catch (error: any) {
      console.error('error from service : ', error);
      throw error?.response?.data as ErrorResponse;
    }
  }

  /**
   * Refresh Producer Appointment Record
   */
  static async refreshAgentAppointmentRecord(token: string, agentId: string) {
    try {
      const url = `${this.host}/account/producer/${agentId}/refresh-producer-appointments`;
      const response = await apiStore.getApiClient(token).get(url);
      message.success(`Producer Appointments Details Refreshed Successfully`);
      return response.data.data?.appointmentsLastUpdatedAt;
    } catch (error: any) {
      console.error('error from service : ', error);
      if (error?.response?.data?.error?.message === 'Insufficient Funds') {
        message.error(
          `Unable to refresh appointments due to insufficient funds in your account. Please ensure sufficient funds and try again.`
        );
      } else {
        message.error(
          `Unable to complete the action. An unexpected error occurred. Please try again later.`
        );
      }
    }
  }

  /**
   * Refresh Agent Appointment Record
   */
  static async refreshAgentAppointmentRecordForAgentPortal(token: string) {
    try {
      const url = `${this.host}${this.modulePath}/me/refresh-appointments`;
      const response = await apiStore.getApiClient(token).get(url);
      message.success(`Producer Appointments Details Refreshed Successfully`);
      return response.data.data?.appointmentsLastUpdatedAt;
    } catch (error: any) {
      console.error('error from service : ', error);
      if (error?.response?.data?.error?.message === 'Insufficient Funds') {
        message.error(
          `Unable to refresh appointments due to insufficient funds in your account. Please ensure sufficient funds and try again.`
        );
      } else {
        message.error(
          `Unable to complete the action. An unexpected error occurred. Please try again later.`
        );
      }
    }
  }

  /**
   * Refresh Agent License Record
   */
  static async refreshAgentLicenseRecord(token: string, agentId: string) {
    try {
      const url = `${this.host}/license/${agentId}/refresh`;
      const response = await apiStore.getApiClient(token).get(url);
      message.success(`Producer License Details Refreshed Successfully`);
      return response.data.data?.licensesLastUpdatedAt;
    } catch (error: any) {
      console.error('error from service : ', error);
      if (error?.response?.data?.error?.message === 'Insufficient Funds') {
        message.error(
          `Unable to refresh licenses due to insufficient funds in your account. Please ensure sufficient funds and try again.`
        );
      } else {
        message.error(
          `Unable to complete the action. An unexpected error occurred. Please try again later.`
        );
      }
    }
  }

  /**
   * Refresh Agent License Record
   */
  static async refreshAgentLicenseRecordForAgentPortal(token: string) {
    try {
      const url = `${this.host}/license/refresh`;
      const response = await apiStore.getApiClient(token).get(url);
      message.success(`Producer License Details Refreshed Successfully`);
      return response.data.data?.licensesLastUpdatedAt;
    } catch (error: any) {
      console.error('error from service : ', error);
      if (error?.response?.data?.error?.message === 'Insufficient Funds') {
        message.error(
          `Unable to refresh licenses due to insufficient funds in your account. Please ensure sufficient funds and try again.`
        );
      } else {
        message.error(
          `Unable to complete the action. An unexpected error occurred. Please try again later.`
        );
      }
    }
  }

  /**
   * Filter For Agent Service
   * @param npn
   * @param token
   * @param email
   * @returns
   */

  static async getFilterDetails(
    bearerToken: string,
    size: number,
    page: number,
    path: string,
    searchString?: string,
    requestBody?: Object,
    assignmentFilters?: AgentAssignmentsFilters | null
    // advancedFilters?: AdvancedFilters
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}/filters`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'DownlineService :: getFilterDetails :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Returns filter info for agent application table
   * @param agencyId
   * @param token
   * @param requestBody
   * @returns
   */

  static async getFilterInfoForGeneralAgent(
    agencyId: string,
    bearerToken: string,
    requestBody: Object = {}
  ): Promise<any> {
    const path = '/agents/agencyId/first-level-downline-agents/filter-options';

    return new Promise(async (resolve, reject) => {
      try {
        const modifiedPath = path.replace('agencyId', agencyId);
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${modifiedPath}`, requestBody);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'AgentService :: getFilterInfoForAgentApplication :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getAgencyStats(bearerToken: string): Promise<any> {
    const path = '/agency/stats';
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);
        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'AgentService :: getAgencyStats :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getAgentsStats(bearerToken: string): Promise<any> {
    const path = '/agents/me/stats';
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          ' AgentService :: getAgencyStats :: Error :: ',
          JSON.stringify(error)
        );
        reject(error);
      }
    });
  }
  /**
   * Service to resend verification email of the bearer token holder
   * @param token auth0 jwt
   * @returns
   */
  static async resendVerificationEmail(token: string) {
    return new Promise(async (resolve, reject) => {
      const path = `/auth/me/verification/resend`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}`);
        resolve(response.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Get Actionable Agent Details
   */
  static async getActionableAgentDetails(
    token: string,
    payload: {
      size: number;
      page: number;
    },
    stateCode?: string,
    showOnlyMissing?: boolean,
    requestBody?: any
  ) {
    try {
      adminStore.setIsActionableAgentFetching(true);
      const url = `${this.host}/account/producer/actionable-agent-details`;
      const url_ = new URL(`${url}`);
      // if (searchString)
      //   url_.searchParams.append(
      //     'searchString',
      //     encodeURIComponent(searchString)
      //   );
      if (payload.size)
        url_.searchParams.append('size', payload.size.toString());
      if (payload.page)
        url_.searchParams.append('page', payload.page.toString());

      if (stateCode) url_.searchParams.append('stateCode', stateCode);
      if (showOnlyMissing)
        url_.searchParams.append('showOnlyMissing', showOnlyMissing.toString());
      // Process requestBody using filterRequestBody function
      if (requestBody) filterRequestBody(requestBody, url_);
      const response = await apiStore.getApiClient(token).get(`${url_}`);
      return response.data;
    } catch (error: any) {
      console.error('error from service : ', error);
    } finally {
      adminStore.setIsActionableAgentFetching(false);
    }
  }

  static async multipleOnboardLookup(token: string, reportId: string) {
    return new Promise(async (resolve, reject) => {
      const path = `${this.modulePath}/verify-multiple-onboard-lookup`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}`, { reportId: `${reportId}` });
        resolve(response.data.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  static async multipleOnboard(token: string, bulkOnboardId: string) {
    return new Promise(async (resolve, reject) => {
      const path = `/agency/bulk/onboard/proceed/${bulkOnboardId}`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}`);
        resolve(response.data.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  static async getHomeStateForAgents(token: string, agentIds: string[]) {
    return new Promise(async (resolve, reject) => {
      const path = `${this.modulePath}/my-agency/home-state-for-agents`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}`, { agentIds: agentIds });
        resolve(response.data.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  static async createAssignmentForLicense(
    token: string,
    producerId: string,
    requestBody: any
  ) {
    return new Promise(async (resolve, reject) => {
      const path = `/account/producer/create-assignment-from-license/${producerId}`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .patch(`${this.host}${path}`, { ...requestBody });
        resolve(response.data.message);
      } catch (error: any) {
        console.error('ERRORR :: ', error);
        reject(error?.response?.data || error);
      }
    });
  }

  static async getAllExpiryCountForAgents(
    filters: AgentsFilters | null,
    pageSize: number | null,
    pageNumber: number | null,
    searchString: string | null,
    accessToken: string,
    paymentConfig?: boolean,
    reqBody?: object,
    agencyId?: string,
    searchType?: string
  ): Promise<PaginatedAgentsSuccessResponse<AgentDocument>> {
    return new Promise(async (resolve, reject) => {
      try {
        let url_ = new URL(
          `${this.host}/account/producer/producer-with-expiring-licenses`
        );

        if (agencyId)
          url_ = new URL(
            `${this.host}/account/producer/producer-with-expiring-licenses-downlines/${agencyId}`
          );

        if (filters) {
          for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
              const filterKey = key as keyof AgentsFilters;
              const filterValue = filters[filterKey];
              if (
                typeof filterValue === 'string' ||
                typeof filterValue === 'boolean'
              ) {
                url_.searchParams.append(
                  encodeURIComponent(filterKey),
                  encodeURIComponent(filterValue)
                );
              }
            }
          }
        }

        if (searchString)
          url_.searchParams.append(
            'searchString',
            encodeURIComponent(searchString)
          );
        if (searchType)
          url_.searchParams.append(
            'searchType',
            encodeURIComponent(searchType)
          );
        if (pageSize) url_.searchParams.append('size', pageSize.toString());
        if (pageNumber) url_.searchParams.append('page', pageNumber.toString());
        if (paymentConfig)
          url_.searchParams.append(
            'paymentNotConfigured',
            paymentConfig.toString()
          );
        // Process requestBody using filterRequestBody function
        if (reqBody) filterRequestBody(reqBody, url_);
        cancelPreviousRequest(apiStore.getTokenForGetAllAgents());
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetAllAgents(cancelTokenSource);
        const agents = await apiStore
          .getApiClient(accessToken, cancelTokenSource)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        apiStore.setTokenForGetAllAgents(undefined);

        resolve(agents.data as PaginatedAgentsSuccessResponse<AgentDocument>);
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          apiStore.setTokenForGetAllAgents(undefined);
        }
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }
}
