import { LoaDetails, StateDetails } from '../types/data/masterData';

import { AgentService } from '../services/agent.service';
import { ApplicantType } from '../enums/applicantType.enum';
import CustomIcon from '../components/common/customiseIcon/customIcon';
import { DrlpConfig } from '../types/data/drlpConfig.type';
import { IconConstants } from '../constants/icons.constants';
import { LicenseClassDto } from '../types/data/licenseClass.type';
import { LicenseConfigurationDto } from '../types/data/licenseConfiguration.type';
import { LoaDto } from '../types/data/loa.type';
import { MasterDataService } from '../services/masterData.service';
import { ResidencyType } from '../enums/residencyType.enum';
import { StateRenewalConfig } from '../types/data/licenseFeesConfig.type';
import { getNameString } from '../utils/name.utils';
import { groupBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

class AppStateInfoStore {
  header: string = '';
  currentAgentId: string = '';
  selectedMenuKey: string = '1';
  stateGroup: string = '';
  menuIcon: React.ReactNode = (<CustomIcon icon={IconConstants.agents} />);
  tooltip: string = '';
  backgroundQuestionsLoader: boolean = false;
  cloneEnable: boolean = false;
  licenseConfigs: LicenseConfigurationDto[] = [];
  formattedMasterData: StateDetails[] = [];
  licenseClasses: LicenseClassDto[] = [];
  loas: LoaDto[] = [];
  drlpConfigs: DrlpConfig[] = [];
  stateRenewalConfigs: StateRenewalConfig[] = [];
  stateIndividualRenewalConfigs: StateRenewalConfig[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getStateRenewalConfigs = (): StateRenewalConfig[] => {
    return this.stateRenewalConfigs;
  };
  getStateIndividualRenewalConfigs = (): StateRenewalConfig[] => {
    return this.stateIndividualRenewalConfigs;
  };

  setStateRenewalConfigs = (value: StateRenewalConfig[]) => {
    this.stateRenewalConfigs = value;
  };

  setStateIndividualRenewalConfigs = (value: StateRenewalConfig[]) => {
    this.stateIndividualRenewalConfigs = value;
  };

  initializeStore = async (token: string) => {
    if (this.getLicenseConfigs().length === 0)
      await this.loadLicenseConfigs(token);
    if (this.getStateRenewalConfigs().length === 0)
      await this.loadStateRenewalConfig(token);
    if (this.getStateIndividualRenewalConfigs().length === 0)
      await this.loadStateIndividualRenewalConfig(token);
    // if (this.getLoas().length === 0) await this.loadLoas(token);
    // if (this.getLcs().length === 0) await this.loadLcs(token);
    // if (this.getDrlpConfigs().length === 0) await this.loadDrlpConfigs(token);
    // if (this.getLicenseFeesConfigs().length === 0)
    //   await this.loadLicenseFeesConfigs(token);
  };

  getLicenseConfigs = (): LicenseConfigurationDto[] => {
    return this.licenseConfigs;
  };

  setLicenseConfigs = (value: LicenseConfigurationDto[]) => {
    this.licenseConfigs = value;
  };

  loadStateRenewalConfig = async (bearerToken: string) => {
    const resp = await MasterDataService.getStateRenewalConfigs(
      bearerToken
    ).catch((e) => {
      console.error(e);
    });
    if (resp) {
      this.setStateRenewalConfigs(resp.data);
    }
  };

  loadStateIndividualRenewalConfig = async (bearerToken: string) => {
    const resp = await MasterDataService.getStateIndividualRenewalConfigs(
      bearerToken
    ).catch((e) => {
      console.error(e);
    });
    if (resp) {
      this.setStateIndividualRenewalConfigs(resp);
    }
  };

  loadLicenseConfigs = async (bearerToken: string) => {
    const resp = await MasterDataService.getLicenseConfigs(bearerToken).catch(
      (e) => {
        console.error(e);
      }
    );
    if (resp) {
      this.setLicenseConfigs(resp.data);
    }
  };

  getDrlpConfigs = (): DrlpConfig[] => {
    return this.drlpConfigs;
  };

  setDrlpConfigs = (value: DrlpConfig[]) => {
    this.drlpConfigs = value;
  };

  loadDrlpConfigs = async (bearerToken: string) => {
    const resp = await MasterDataService.getDrlpConfigs(bearerToken).catch(
      (e) => {
        console.error(e);
      }
    );
    if (resp) {
      this.setDrlpConfigs(resp.data);
    }
  };

  calculateFeesForAgencyLicense(
    stateCode: string,
    loaCount: number,
    homeState: string,
    type: ApplicantType
  ): { isRetaliatory: boolean; fees: number; retaliatoryLink?: string } {
    let fees = 0;
    const residencyType =
      homeState === stateCode
        ? ResidencyType.Resident
        : ResidencyType.NonResident;
    const foundConfig = this.stateRenewalConfigs.find((itr) => {
      return (
        itr.stateCode === stateCode && itr.residencyType.includes(residencyType)
      );
    });

    if (foundConfig) {
      fees += foundConfig.applicationFee;
      fees += foundConfig.transFee;
    }
    return { isRetaliatory: false, fees };
  }

  calculateFeesForProducerLicenses(stateCode: string, isActive: boolean) {
    let fees = 0;

    const stateConfig = this.getStateIndividualRenewalConfigs().find(
      (config) =>
        config.stateCode === stateCode &&
        config.residencyType.includes(ResidencyType.NonResident) &&
        (config.applicantType === ApplicantType.INDIVIDUAL ||
          config.applicantType === ApplicantType.BOTH) &&
        (!isActive ? config.applicationFee : config.renewalFee)
    );

    if (stateConfig) {
      if (isActive) {
        fees += stateConfig.transFee || 0;
        fees += stateConfig.renewalFee || 0;
      } else {
        fees += stateConfig.transFee || 0;
        fees += stateConfig.applicationFee || 0;
      }
    } else {
      // if config is not found add transaction fee for that application
      if (isActive) {
        fees += 5.6;
      } else {
        fees += 5.6;
      }
    }

    return fees;
  }

  /**
   * This function should only be used for agents
   * @returns
   */
  getLicenseConfigsMasterData = (
    applicantType = ApplicantType.INDIVIDUAL
  ): StateDetails[] => {
    const individualConfigs = this.licenseConfigs.filter((itr) => {
      return (
        itr.applicantType === applicantType ||
        itr.applicantType === ApplicantType.BOTH
      );
    });
    const stateGroupedData = groupBy(individualConfigs, 'stateCode');
    const result: StateDetails[] = Object.entries(stateGroupedData).map(
      ([stateCode, stateLicenseConfig]) => {
        const lcGroupedData = groupBy(stateLicenseConfig, 'licenseClassCode');
        const lcs = Object.entries(lcGroupedData).map(([lcCode, lcConfig]) => {
          const notRetiredLc = lcConfig.filter((d) => !d.isRetired);
          const notRetiredLcDetails = notRetiredLc?.[0];
          const residencyType: ResidencyType =
            (notRetiredLcDetails?.residencyType ||
              lcConfig[0].residencyType) as unknown as ResidencyType;
          return {
            name: this.getLcNameFromCode(
              notRetiredLcDetails?.licenseClassCode || lcCode
            ),
            lcCode: notRetiredLcDetails?.licenseClassCode || lcCode,
            licenseClass:
              notRetiredLcDetails?.licenseClass || lcConfig[0].licenseClass,
            residencyType: residencyType, // move this field to LOA or change whole config structure
            loaData: lcConfig.map((loaItr) => {
              return {
                loaCode: loaItr.loaCode,
                loa: loaItr.loa,
                fees: {},
                id: loaItr._id || loaItr.id,
                residencyType: loaItr.residencyType,
                isRetired: loaItr.isRetired,
                referenceLicenseConfig: loaItr.referenceLicenseConfig,
              } as LoaDetails;
            }),
          };
        });
        return {
          stateCode: stateCode,
          licenseClasses: lcs,
        };
      }
    );
    return result;
  };

  /**
   * This function should only be used for agents
   * @returns license configuration without duplicate loas
   */
  getLicenseConfigsMasterDataWithoutDuplicates = (
    applicantType: ApplicantType = ApplicantType.INDIVIDUAL
  ): StateDetails[] => {
    const individualConfigs = this.licenseConfigs.filter((itr) => {
      return (
        itr.applicantType &&
        [ApplicantType.INDIVIDUAL, applicantType].includes(itr.applicantType) &&
        !itr.isRetired
      );
    });
    const stateGroupedData = groupBy(individualConfigs, 'stateCode');
    const result: StateDetails[] = Object.entries(stateGroupedData).map(
      ([stateCode, stateLicenseConfig]) => {
        const lcGroupedData = groupBy(stateLicenseConfig, 'licenseClassCode');
        const lcs = Object.entries(lcGroupedData).map(([lcCode, lcConfig]) => {
          const notRetiredLc = lcConfig.filter((d) => !d.isRetired);
          const notRetiredLcDetails = notRetiredLc?.[0];
          const residencyType: ResidencyType =
            (notRetiredLcDetails?.residencyType ||
              lcConfig[0].residencyType) as unknown as ResidencyType;

          return {
            name: this.getLcNameFromCode(
              notRetiredLcDetails?.licenseClassCode || lcCode
            ),
            lcCode: notRetiredLcDetails?.licenseClassCode || lcCode,
            licenseClass:
              notRetiredLcDetails?.licenseClass || lcConfig[0].licenseClass,
            residencyType: residencyType, // move this field to LOA or change whole config structure
            loaData: notRetiredLc.map((loaItr) => {
              return {
                loaCode: loaItr.loaCode,
                loa: loaItr.loa,
                fees: {},
                id: loaItr._id || loaItr.id,
                residencyType: loaItr.residencyType,
                isRetired: loaItr.isRetired,
              } as LoaDetails;
            }),
          };
        });
        return {
          stateCode: stateCode,
          licenseClasses: lcs,
        };
      }
    );
    return result;
  };

  getLoasForRts = (): LoaDto[] => {
    const filterDuplicates = new Set();
    const loaData: LoaDto[] = [];
    this.licenseConfigs.forEach((d) => {
      const loaDetails = `${d.loa}${d.loaCode}`;
      if (filterDuplicates.has(loaDetails)) return;
      filterDuplicates.add(loaDetails);
      loaData.push({
        loa: d.loa,
        loaCode: d.loaCode,
      });
    });
    return loaData;
  };

  getLoas = (): LoaDto[] => {
    return this.loas;
  };

  setLoas = (value: LoaDto[]) => {
    this.loas = value;
  };

  loadLoas = async (bearerToken: string) => {
    const resp = await MasterDataService.getLoas(bearerToken).catch((e) => {
      console.error(e);
    });
    if (resp) {
      this.setLoas(resp.data);
    }
  };

  getLoaNameFromCode = (loaCode: string): string => {
    const loaData = this.licenseConfigs.find((loa) => loa.loaCode === loaCode);
    if (loaData) return loaData.loa;
    else return loaCode;
  };

  getNameByCodeFromLicenseDetails = (
    loaCode: string | null,
    stateCode: string,
    lcCode: string
  ): string => {
    const loaData = this.licenseConfigs.find(
      (loa) =>
        (!loaCode || loa.loaCode === loaCode) &&
        loa.stateCode === stateCode &&
        loa.licenseClassCode === lcCode
    );
    if (loaData) return loaData.loa;
    if (loaCode) return loaCode;
    return lcCode;
  };

  getLcs = (): LicenseClassDto[] => {
    return this.licenseClasses;
  };

  setLcs = (value: LicenseClassDto[]) => {
    this.licenseClasses = value;
  };

  loadLcs = async (bearerToken: string) => {
    const resp = await MasterDataService.getLicenseClasses(bearerToken).catch(
      (e) => {
        console.error(e);
      }
    );
    if (resp) {
      this.setLcs(resp.data);
    }
  };

  getLcNameFromCode = (lcCode: string): string => {
    const lcData = this.licenseClasses.find(
      (lcData) => lcData.licenseClassCode === lcCode
    );
    if (lcData) return lcData.licenseClass;
    else return lcCode;
  };

  getResidencyType = (lcCode: string, stateCode: string): string => {
    for (const item of this.licenseConfigs) {
      if (item.stateCode === stateCode && item.licenseClassCode === lcCode) {
        return item.residencyType;
      }
    }
    return 'N/A';
  };

  setIcon = (icon: any) => {
    this.menuIcon = icon;
  };

  setCurrentAgentId = (id: string) => {
    this.currentAgentId = id;
  };

  setBackgroundQuestionsLoader = (state: boolean) => {
    this.backgroundQuestionsLoader = state;
  };

  setHeader = (headerString: string) => {
    this.header = headerString;
  };

  setStateGroup = (stateGroup: string) => {
    this.stateGroup = stateGroup;
  };

  setSelectedMenuKey = (value: string) => {
    this.selectedMenuKey = value;
  };

  setTooltip = (value: string) => {
    this.tooltip = value;
  };

  getHeader = () => {
    return this.header;
  };

  setCloneEnabled = (value: boolean) => {
    this.cloneEnable = value;
  };

  getCloneValue = () => {
    return this.cloneEnable;
  };

  getAgentDetail = async (id: string, bearerToken: string) => {
    const response: any = await AgentService.getAgentDetails(
      id,
      bearerToken || ''
    ).catch((error) => console.error(error));

    if (response.data) {
      this.setHeader(getNameString(response.data.name));
      this.setCurrentAgentId(response.data.id);
    }
  };
}

export const appStateInfoStore = new AppStateInfoStore();
