import { Card, Progress } from 'antd';
import { HomeOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

import { ProducerDetails } from '../onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import React from 'react';
import { getDisplayFees } from '../../../utils/common.utils';

export type statDetails = {
  totalStates: number;
  activeStates: number;
  statesSelected: number;
  territoriesSelected: number;
  totalTerriotories: number;
  totalEstimatedCost: number;
};

interface ManageAssignmentsByProducerProps {
  producerDetails?: ProducerDetails;
  producerStatDetails: statDetails;
}

const statStyle = {
  fontWeight: 500,
  fontSize: 10,
  lineHeight: '24px',
};

const ProducerStatDetails: React.FC<ManageAssignmentsByProducerProps> = ({
  producerDetails,
  producerStatDetails,
}) => {
  const homeState = producerDetails?.residentState;
  return (
    <div
      style={{
        display: 'flex',
        gap: 16,
        flex: 1,
        width: '100%',
      }}
      className="producer-assignment-card figtree"
    >
      <Card style={{ flex: 1 }}>
        <div
          style={{
            display: 'flex',
            gap: 8,
            width: 'fit-content',
            minWidth: 375,
            flexWrap: 'wrap',
            flex: 1,
            marginTop: 8,
          }}
        >
          {' '}
          <div
            style={{
              width: 78,
              height: 78,
              borderRadius: '50%',
              display: 'grid',
              placeContent: 'center',
              backgroundColor: '#EDF9FF',
              fontSize: 32,
              color: '#0074B0',
              fontWeight: 'bolder',
            }}
          >
            {producerDetails?.name?.firstName?.[0] || ''}
            {producerDetails?.name?.lastName?.[0] || ''}
          </div>
          <div style={{ marginTop: 6, marginRight: 24 }}>
            <div
              style={{
                fontSize: 18,
                fontWeight: '500',
                wordBreak: 'break-word',
                lineHeight: '24px',
                color: '#222222',
                display: 'flex',
                gap: 16,
                alignItems: 'center',
              }}
            >
              {producerDetails?.name?.firstName || ''}{' '}
              {producerDetails?.name?.lastName || ''}{' '}
              {/* {homeState ? (
                <StatesCircled
                  label={homeState}
                  key={homeState}
                  showTooltip={true}
                  tooltipTitle={`${getStateNameFromCode(homeState)}`}
                />
              ) : null} */}
            </div>
            <div
              style={{
                display: 'flex',
                gap: 24,
                fontSize: 10,
                lineHeight: '20px',
                fontWeight: 400,
                alignItems: 'center',
                color: '#1D2C50',
              }}
              className="overide-icon-size"
            >
              <p
                style={{
                  minWidth: 70,
                }}
              >
                <PhoneOutlined style={{ marginRight: 6 }} />
                {producerDetails?.businessPhone || '-'}
              </p>
              <p
                style={{
                  minWidth: 70,
                }}
              >
                <MailOutlined style={{ marginRight: 6 }} />
                {producerDetails?.businessEmail || '-'}
              </p>
              <p
                style={{
                  display: 'flex',
                  fontSize: 12,
                }}
              >
                <HomeOutlined style={{ marginRight: 6 }} />
                {homeState || '-'}
              </p>
              {/* <UserAddOutlined style={{ marginRight: 3 }} />{' '} */}
            </div>
          </div>
        </div>
      </Card>
      <Card>
        <div
          className="flex-col-center"
          style={{ justifyContent: 'space-between' }}
        >
          <Progress
            type="circle"
            trailColor="#00A6FB"
            strokeWidth={12}
            strokeColor={'#001F45'}
            format={() => (
              <span style={{ ...statStyle }}>
                {producerStatDetails.activeStates}/
                {producerStatDetails.totalStates || 1}
              </span>
            )}
            percent={
              (producerStatDetails.activeStates /
                producerStatDetails.totalStates) *
              100
            }
            size={48}
          />
          <div className="medium-title-custom text-center">
            States with <div>Active Licenses</div>
          </div>
        </div>
      </Card>
      <Card>
        <div className="flex-col-center">
          <Progress
            type="circle"
            trailColor="#00A6FB"
            strokeWidth={12}
            style={{ marginTop: 8 }}
            strokeColor={'#001F45'}
            format={() => (
              <span style={{ ...statStyle }}>
                {producerStatDetails.territoriesSelected}/
                {producerStatDetails.totalTerriotories || 1}
              </span>
            )}
            percent={
              (producerStatDetails.territoriesSelected /
                producerStatDetails.totalTerriotories) *
              100
            }
            size={48}
          />
          <div
            className="medium-title-custom text-center"
            style={{ marginTop: 8 }}
          >
            Territories Selected
          </div>
        </div>
      </Card>
      <Card>
        <div className="flex-col-center">
          <Progress
            type="circle"
            trailColor="#00A6FB"
            strokeWidth={12}
            style={{ marginTop: 8 }}
            strokeColor={'#001F45'}
            format={() => (
              <span style={{ ...statStyle }}>
                {producerStatDetails.statesSelected}/
                {producerStatDetails.totalStates || 1}
              </span>
            )}
            percent={
              (producerStatDetails.statesSelected /
                producerStatDetails.totalStates) *
              100
            }
            size={48}
          />
          <div
            className="medium-title-custom text-center"
            style={{ marginTop: 8 }}
          >
            States Selected
          </div>
        </div>
      </Card>
      <Card className="flex-center">
        <div
          style={{
            fontWeight: 600,
            fontSize: 23,
            lineHeight: '24px',
            color: '#0F9D58',
            textAlign: 'center',
            marginTop: 12,
            marginBottom: 16,
          }}
        >
          {' '}
          $ {getDisplayFees(producerStatDetails?.totalEstimatedCost) || 0}{' '}
        </div>
        <div className="medium-title-custom text-center">
          Total Estimated Cost
        </div>
      </Card>
    </div>
  );
};

export default ProducerStatDetails;
