import {
  AssignmentOptions,
  AssignmentStateTerritoriesProps,
  StateProps,
} from './individualOnboardV2Type.type';
import { Checkbox, Tooltip } from 'antd';
import { RightOutlined, SearchOutlined } from '@ant-design/icons';

import EmptyState from '../../../../components/common/emptyState/emptyState';
import React from 'react';
import { State } from '../../../../components/agencyLicensing/agencyLicensingInterfaces.type';

const AssignmentStateTerritories: React.FC<AssignmentStateTerritoriesProps> = ({
  currentSelected,
  onSelect,
  filteredItems,
  searchQuery,
  setSearchQuery,
  placeHolder,
  selectedCount,
  type,
  onCheck,
  checkBoxProps = {},
  disabledItems = [],
  isCheckboxNeeded,
  activeState,
  isAssignmentPage,
  setSelectedTab,
  selectedTab,
  selectedItems,
}) => {
  const handleSelect = (item: State | string) => {
    if (setSelectedTab) setSelectedTab(AssignmentOptions.TERRITORIES);
    if (type === 'State' && typeof item !== 'string') {
      onSelect(item.name);
      return;
    }
    onSelect(item as string);
  };

  return (
    <div
      style={{
        width: '220px',
        borderRadius: '6px',
        border: '1px solid #D6E4EB',
        backgroundColor: '#FFFFFF',
        padding: '16px',
        gap: isAssignmentPage && placeHolder === 'Territories' ? 16 : '24px',
        height: 'fit-content',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isAssignmentPage ? null : (
        <div
          style={{
            height: '24px',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            color: '#000000',
            // textAlign: 'center',
          }}
        >
          {selectedCount?.length
            ? `${selectedCount?.length} ${placeHolder} Selected`
            : null}
        </div>
      )}
      {isAssignmentPage && placeHolder === 'Territories' ? (
        <div>
          <div
            className="sub-title-placeholder"
            style={{
              color: '#989898',
            }}
          >
            States
          </div>
          <div
            onClick={() => {
              if (setSelectedTab) {
                handleSelect('Alabama');
                setSelectedTab(AssignmentOptions.STATE);
              }
            }}
            className="override-icon-size"
            style={{
              display: 'flex',
              gap: 8,
              padding: '8px',
              margin: '8px 0 16px',
              justifyContent: 'space-between',
              borderRadius: '6px',
              backgroundColor:
                selectedTab === AssignmentOptions.STATE ? '#E2F0FD' : '#FFFFFF',
              cursor: 'pointer',
            }}
          >
            <Checkbox
              // todo
              // onChange={(e.target.checked)} setSelectedItems([])
              checked={
                // AssignmentOptions.STATE === selectedTab ||
                !!selectedItems?.length
              }
            />{' '}
            All States <RightOutlined />
          </div>

          <div
            className="sub-title-placeholder"
            style={{
              color: '#989898',
            }}
          >
            Territories
          </div>
        </div>
      ) : null}
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#F5F6F7',
            borderRadius: '6px',
            padding: '0 12px',
            height: '32px',
          }}
        >
          <SearchOutlined style={{ marginRight: '8px' }} />
          <input
            type="text"
            placeholder={`Search ${placeHolder}`}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              width: '100%',
              border: 'none',
              backgroundColor: 'transparent',
              outline: 'none',
              fontSize: '14px',
              color: '#333',
            }}
          />
        </div>
      </div>
      <div
        style={{
          overflowY: 'auto',
          height: `calc(100vh - ${
            isAssignmentPage
              ? placeHolder === 'Territories'
                ? '516px'
                : '406px'
              : '400px'
          })`,
        }}
      >
        {filteredItems.length === 0 ? (
          <div
            style={{
              display: 'grid',
              placeContent: 'center',
              height: '475px',
            }}
          >
            <EmptyState
              image="empty data"
              heading=""
              width="100%"
              content={`No ${placeHolder} Found`}
            />
          </div>
        ) : (
          filteredItems.map((item, index) => {
            const isState = type === 'State' && typeof item !== 'string';
            const isSelected =
              isAssignmentPage && selectedTab === AssignmentOptions.STATE
                ? false
                : currentSelected ===
                  (isState ? (item as State).name : (item as string));
            const isSelectedInTerritory = disabledItems?.includes(
              isState ? (item as State).name : (item as string)
            );

            return (
              <div
                key={isState ? (item as State).code : (item as string)}
                onClick={() => handleSelect(item)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  marginBottom: '8px',
                  padding: '8px',
                  borderRadius: '6px',
                  backgroundColor: isSelected ? '#E2F0FD' : '#FFFFFF',
                  cursor: 'pointer',
                }}
              >
                <Tooltip title={(item as StateProps)?.tooltipText}>
                  {isCheckboxNeeded ? (
                    <Checkbox
                      checked={
                        selectedCount
                          ?.map((d) => d.name)
                          ?.includes(
                            isState ? (item as State).name : (item as string)
                          ) || isSelectedInTerritory
                      }
                      onChange={(e) => {
                        e.preventDefault();
                        onCheck(
                          e.target.checked,
                          isState ? (item as State).name : (item as string)
                        );
                      }}
                      disabled={
                        isSelectedInTerritory ||
                        (type === 'State' && (item as StateProps)?.isDisabled)
                      }
                      {...checkBoxProps}
                      style={{ marginRight: 12 }}
                    />
                  ) : null}
                </Tooltip>
                {!isState ? null : (
                  <div
                    style={{
                      width: '28px',
                      height: '24px',
                      borderRadius: '26px',
                      border: '1px solid',
                      padding: '2px 6px',
                      backgroundColor: activeState.includes(
                        (item as State).code
                      )
                        ? '#F1FEF4'
                        : '#F1F8FE',
                      borderColor: activeState.includes((item as State).code)
                        ? '#0F9D58'
                        : '#437EE1',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '10px',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: activeState.includes((item as State).code)
                          ? '#0F9D58'
                          : '#437EE1',
                      }}
                    >
                      {(item as State).code}
                    </span>
                  </div>
                )}
                <span
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#000000',
                    marginLeft: !isCheckboxNeeded && !isState ? 8 : 0,
                  }}
                >
                  {isState ? (item as State).name : (item as string)}
                </span>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default AssignmentStateTerritories;
